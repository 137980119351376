export default [
	    {
	        "id": "pars__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "pars__user_id"
	    },
	    {
	        "id": "pars__hire_date_score",
	        "header": "Hire Date Score",
	        "fieldName": "pars__hire_date_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__work_shift_score",
	        "header": "Work Shift Score",
	        "fieldName": "pars__work_shift_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__vehicle_score",
	        "header": "Vehicle Score",
	        "fieldName": "pars__vehicle_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__route_score",
	        "header": "Route Score",
	        "fieldName": "pars__route_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__position_risk_score",
	        "header": "Position Risk Score",
	        "fieldName": "pars__position_risk_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__speeding_days_score",
	        "header": "Speeding Days Score",
	        "fieldName": "pars__speeding_days_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__move_violations_warning_score",
	        "header": "Move Violations Warning Score",
	        "fieldName": "pars__move_violations_warning_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__hos_risk",
	        "header": "Hos Risk",
	        "fieldName": "pars__hos_risk",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__motorist_complaints_score",
	        "header": "Motorist Complaints Score",
	        "fieldName": "pars__motorist_complaints_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__dash_cam_coaching_score",
	        "header": "Dash Cam Coaching Score",
	        "fieldName": "pars__dash_cam_coaching_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__preventable_accident_score",
	        "header": "Preventable Accident Score",
	        "fieldName": "pars__preventable_accident_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__clean_inspection_adj_score",
	        "header": "Clean Inspection Adj Score",
	        "fieldName": "pars__clean_inspection_adj_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__remedial_training_adj_score",
	        "header": "Remedial Training Adj Score",
	        "fieldName": "pars__remedial_training_adj_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__behavior_risk",
	        "header": "Behavior Risk",
	        "fieldName": "pars__behavior_risk",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__positive_adjustments",
	        "header": "Positive Adjustments",
	        "fieldName": "pars__positive_adjustments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    },
	    {
	        "id": "pars__par_score",
	        "header": "Par Score",
	        "fieldName": "pars__par_score",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	    }
	];