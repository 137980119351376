import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import UsersFilteredGridEditor from '../components/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditor from '../components/Grid/UsersFilteredSideGridEditor.js';

export default function UsersManager(props) {	
	return <ManagerScreen
				title="Users"
				id="UsersManager"
				fullModeComponent={<UsersFilteredGridEditor
										reference="usersFilteredGridEditor"
										useRemoteDuplicate={true}
										useUploadDownload={true}
									/>}
				sideModeComponent={<UsersFilteredSideGridEditor
										reference="usersFilteredGridEditor"
										useRemoteDuplicate={true}
										useUploadDownload={true}
									/>}
			/>;
}
