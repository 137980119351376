/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import DriverIncentivesGridColumns from '../../Grid/Columns/DriverIncentivesGridColumns.js';

export default function DriverIncentivesGridPanel(props) {
	return <GridPanel
				reference="DriverIncentivesGridPanel"
				model="DriverIncentives"
				usePermissions={true}
				columnsConfig={DriverIncentivesGridColumns}

				{...props}
			/>;
}