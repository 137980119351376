import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Permissions = {
	
	name: 'Permissions',
	
	model: {

		idProperty: 'permissions__id',
		displayProperty: 'permissions__id',
		sortProperty: 'permissions__id',
		
		sorters: null,

		validator: yup.object({
			permissions__user_id: yup.number().integer().nullable(),
			permissions__group_id: yup.number().integer().nullable(),
			permissions__view_reports: yup.boolean().nullable(),
			permissions__view_dashboard: yup.boolean().nullable(),
			permissions__view_attachments: yup.boolean().nullable(),
			permissions__add_attachments: yup.boolean().nullable(),
			permissions__edit_attachments: yup.boolean().nullable(),
			permissions__delete_attachments: yup.boolean().nullable(),
			permissions__view_groups: yup.boolean().nullable(),
			permissions__view_driver_incentives: yup.boolean().nullable(),
			permissions__add_driver_incentives: yup.boolean().nullable(),
			permissions__edit_driver_incentives: yup.boolean().nullable(),
			permissions__delete_driver_incentives: yup.boolean().nullable(),
			permissions__view_driver_infractions: yup.boolean().nullable(),
			permissions__add_driver_infractions: yup.boolean().nullable(),
			permissions__edit_driver_infractions: yup.boolean().nullable(),
			permissions__delete_driver_infractions: yup.boolean().nullable(),
			permissions__view_engagement_types: yup.boolean().nullable(),
			permissions__add_engagement_types: yup.boolean().nullable(),
			permissions__edit_engagement_types: yup.boolean().nullable(),
			permissions__delete_engagement_types: yup.boolean().nullable(),
			permissions__view_engagements: yup.boolean().nullable(),
			permissions__add_engagements: yup.boolean().nullable(),
			permissions__edit_engagements: yup.boolean().nullable(),
			permissions__delete_engagements: yup.boolean().nullable(),
			permissions__view_global_values: yup.boolean().nullable(),
			permissions__add_global_values: yup.boolean().nullable(),
			permissions__edit_global_values: yup.boolean().nullable(),
			permissions__delete_global_values: yup.boolean().nullable(),
			permissions__add_groups: yup.boolean().nullable(),
			permissions__edit_groups: yup.boolean().nullable(),
			permissions__delete_groups: yup.boolean().nullable(),
			permissions__view_groups_users: yup.boolean().nullable(),
			permissions__add_groups_users: yup.boolean().nullable(),
			permissions__edit_groups_users: yup.boolean().nullable(),
			permissions__delete_groups_users: yup.boolean().nullable(),
			permissions__view_users: yup.boolean().nullable(),
			permissions__add_users: yup.boolean().nullable(),
			permissions__edit_users: yup.boolean().nullable(),
			permissions__delete_users: yup.boolean().nullable(),
			permissions__view_permissions: yup.boolean().nullable(),
			permissions__add_permissions: yup.boolean().nullable(),
			permissions__edit_permissions: yup.boolean().nullable(),
			permissions__delete_permissions: yup.boolean().nullable(),
			permissions__view_pars: yup.boolean().nullable(),
			permissions__add_pars: yup.boolean().nullable(),
			permissions__edit_pars: yup.boolean().nullable(),
			permissions__delete_pars: yup.boolean().nullable(),
			permissions__view_driver_insights: yup.boolean().nullable(),
			permissions__add_driver_insights: yup.boolean().nullable(),
			permissions__edit_driver_insights: yup.boolean().nullable(),
			permissions__delete_driver_insights: yup.boolean().nullable(),
			permissions__view_driver_insight_subjects: yup.boolean().nullable(),
			permissions__add_driver_insight_subjects: yup.boolean().nullable(),
			permissions__edit_driver_insight_subjects: yup.boolean().nullable(),
			permissions__delete_driver_insight_subjects: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'permissions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: '1. Overall', },
			{ name: 'permissions__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, editorType: {"type":"GroupsComboEditor","loadAfterRender":!1}, fieldGroup: '1. Overall', },
			{ name: 'permissions__view_reports', mapping: 'view_reports', title: 'View Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Overall', },
			{ name: 'permissions__view_dashboard', mapping: 'view_dashboard', title: 'View Dashboard', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Overall', },
			{ name: 'permissions__view_attachments', mapping: 'view_attachments', title: 'View Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__add_attachments', mapping: 'add_attachments', title: 'Add Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__edit_attachments', mapping: 'edit_attachments', title: 'Edit Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__delete_attachments', mapping: 'delete_attachments', title: 'Delete Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__view_groups', mapping: 'view_groups', title: 'View Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__view_driver_incentives', mapping: 'view_driver_incentives', title: 'View Driver Incentive', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Driver Incentive', },
			{ name: 'permissions__add_driver_incentives', mapping: 'add_driver_incentives', title: 'Add Driver Incentive', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Driver Incentive', },
			{ name: 'permissions__edit_driver_incentives', mapping: 'edit_driver_incentives', title: 'Edit Driver Incentive', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Driver Incentive', },
			{ name: 'permissions__delete_driver_incentives', mapping: 'delete_driver_incentives', title: 'Delete Driver Incentive', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Driver Incentive', },
			{ name: 'permissions__view_driver_infractions', mapping: 'view_driver_infractions', title: 'View Driver Infraction', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Driver Infraction', },
			{ name: 'permissions__add_driver_infractions', mapping: 'add_driver_infractions', title: 'Add Driver Infraction', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Driver Infraction', },
			{ name: 'permissions__edit_driver_infractions', mapping: 'edit_driver_infractions', title: 'Edit Driver Infraction', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Driver Infraction', },
			{ name: 'permissions__delete_driver_infractions', mapping: 'delete_driver_infractions', title: 'Delete Driver Infraction', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Driver Infraction', },
			{ name: 'permissions__view_engagement_types', mapping: 'view_engagement_types', title: 'View Engagement Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Engagement Types', },
			{ name: 'permissions__add_engagement_types', mapping: 'add_engagement_types', title: 'Add Engagement Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Engagement Types', },
			{ name: 'permissions__edit_engagement_types', mapping: 'edit_engagement_types', title: 'Edit Engagement Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Engagement Types', },
			{ name: 'permissions__delete_engagement_types', mapping: 'delete_engagement_types', title: 'Delete Engagement Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Engagement Types', },
			{ name: 'permissions__view_engagements', mapping: 'view_engagements', title: 'View Engagements', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Engagements', },
			{ name: 'permissions__add_engagements', mapping: 'add_engagements', title: 'Add Engagements', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Engagements', },
			{ name: 'permissions__edit_engagements', mapping: 'edit_engagements', title: 'Edit Engagements', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Engagements', },
			{ name: 'permissions__delete_engagements', mapping: 'delete_engagements', title: 'Delete Engagements', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Engagements', },
			{ name: 'permissions__view_global_values', mapping: 'view_global_values', title: 'View Global Value', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Global Value', },
			{ name: 'permissions__add_global_values', mapping: 'add_global_values', title: 'Add Global Value', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Global Value', },
			{ name: 'permissions__edit_global_values', mapping: 'edit_global_values', title: 'Edit Global Value', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Global Value', },
			{ name: 'permissions__delete_global_values', mapping: 'delete_global_values', title: 'Delete Global Value', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. Global Value', },
			{ name: 'permissions__add_groups', mapping: 'add_groups', title: 'Add Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__edit_groups', mapping: 'edit_groups', title: 'Edit Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__delete_groups', mapping: 'delete_groups', title: 'Delete Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Groups', },
			{ name: 'permissions__view_groups_users', mapping: 'view_groups_users', title: 'View Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__add_groups_users', mapping: 'add_groups_users', title: 'Add Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__edit_groups_users', mapping: 'edit_groups_users', title: 'Edit Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__delete_groups_users', mapping: 'delete_groups_users', title: 'Delete Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Groups Users', },
			{ name: 'permissions__view_users', mapping: 'view_users', title: 'View Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__add_users', mapping: 'add_users', title: 'Add Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__edit_users', mapping: 'edit_users', title: 'Edit Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__delete_users', mapping: 'delete_users', title: 'Delete Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__view_permissions', mapping: 'view_permissions', title: 'View Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Permissions', },
			{ name: 'permissions__add_permissions', mapping: 'add_permissions', title: 'Add Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Permissions', },
			{ name: 'permissions__edit_permissions', mapping: 'edit_permissions', title: 'Edit Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Permissions', },
			{ name: 'permissions__delete_permissions', mapping: 'delete_permissions', title: 'Delete Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. Permissions', },
			{ name: 'permissions__view_pars', mapping: 'view_pars', title: 'View Pars', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Pars', },
			{ name: 'permissions__add_pars', mapping: 'add_pars', title: 'Add Pars', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Pars', },
			{ name: 'permissions__edit_pars', mapping: 'edit_pars', title: 'Edit Pars', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Pars', },
			{ name: 'permissions__delete_pars', mapping: 'delete_pars', title: 'Delete Pars', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. Pars', },
			{ name: 'permissions__view_driver_insights', mapping: 'view_driver_insights', title: 'View Driver Insights', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Driver Insights', },
			{ name: 'permissions__add_driver_insights', mapping: 'add_driver_insights', title: 'Add Driver Insights', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Driver Insights', },
			{ name: 'permissions__edit_driver_insights', mapping: 'edit_driver_insights', title: 'Edit Driver Insights', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Driver Insights', },
			{ name: 'permissions__delete_driver_insights', mapping: 'delete_driver_insights', title: 'Delete Driver Insights', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Driver Insights', },
			{ name: 'permissions__view_driver_insight_subjects', mapping: 'view_driver_insight_subjects', title: 'View Driver Insight Subjects', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Driver Insight Submects', },
			{ name: 'permissions__add_driver_insight_subjects', mapping: 'add_driver_insight_subjects', title: 'Add Driver Insight Subjects', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Driver Insight Submects', },
			{ name: 'permissions__edit_driver_insight_subjects', mapping: 'edit_driver_insight_subjects', title: 'Edit Driver Insight Subjects', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Driver Insight Submects', },
			{ name: 'permissions__delete_driver_insight_subjects', mapping: 'delete_driver_insight_subjects', title: 'Delete Driver Insight Subjects', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Driver Insight Submects', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_manager', mapping: 'user.is_manager', title: 'Is Manager?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_ftp_user', mapping: 'user.is_ftp_user', title: 'FTP User?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_id', mapping: 'user.mpp_id', title: 'MPP', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_type', mapping: 'user.mpp_type', title: 'MPP Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_terminal', mapping: 'user.mpp_terminal', title: 'MPP Terminal', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_days_since', mapping: 'user.mpp_days_since', title: 'MPP Days Since', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__hire_date', mapping: 'user.hire_date', title: 'Hire Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__date_of_birth', mapping: 'user.date_of_birth', title: 'Date Of Birth', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'permissions__user_id',
			// 'permissions__group_id',
			// 'permissions__view_reports',
			// 'permissions__view_dashboard',
			// 'permissions__view_attachments',
			// 'permissions__add_attachments',
			// 'permissions__edit_attachments',
			// 'permissions__delete_attachments',
			// 'permissions__view_groups',
			// 'permissions__view_driver_incentives',
			// 'permissions__add_driver_incentives',
			// 'permissions__edit_driver_incentives',
			// 'permissions__delete_driver_incentives',
			// 'permissions__view_driver_infractions',
			// 'permissions__add_driver_infractions',
			// 'permissions__edit_driver_infractions',
			// 'permissions__delete_driver_infractions',
			// 'permissions__view_engagement_types',
			// 'permissions__add_engagement_types',
			// 'permissions__edit_engagement_types',
			// 'permissions__delete_engagement_types',
			// 'permissions__view_engagements',
			// 'permissions__add_engagements',
			// 'permissions__edit_engagements',
			// 'permissions__delete_engagements',
			// 'permissions__view_global_values',
			// 'permissions__add_global_values',
			// 'permissions__edit_global_values',
			// 'permissions__delete_global_values',
			// 'permissions__add_groups',
			// 'permissions__edit_groups',
			// 'permissions__delete_groups',
			// 'permissions__view_groups_users',
			// 'permissions__add_groups_users',
			// 'permissions__edit_groups_users',
			// 'permissions__delete_groups_users',
			// 'permissions__view_users',
			// 'permissions__add_users',
			// 'permissions__edit_users',
			// 'permissions__delete_users',
			// 'permissions__view_permissions',
			// 'permissions__add_permissions',
			// 'permissions__edit_permissions',
			// 'permissions__delete_permissions',
			// 'permissions__view_pars',
			// 'permissions__add_pars',
			// 'permissions__edit_pars',
			// 'permissions__delete_pars',
			// 'permissions__view_driver_insights',
			// 'permissions__add_driver_insights',
			// 'permissions__edit_driver_insights',
			// 'permissions__delete_driver_insights',
			// 'permissions__view_driver_insight_subjects',
			// 'permissions__add_driver_insight_subjects',
			// 'permissions__edit_driver_insight_subjects',
			// 'permissions__delete_driver_insight_subjects'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.permissions__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.permissions__user_id);
				}
				return entity;
			},

			getGroup: async function() {
				const Groups = this.getAssociatedRepository('Groups');
				let entity = Groups.getById(this.permissions__group_id);
				if (!entity) {
					entity = await Groups.getSingleEntityFromServer(this.permissions__group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Permissions;
