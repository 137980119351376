import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import DriverInsightsFilteredGridEditor from '../components/Grid/DriverInsightsFilteredGridEditor.js';
import DriverInsightsFilteredSideGridEditor from '../components/Grid/DriverInsightsFilteredSideGridEditor.js';

export default function DriverInsightsManager(props) {
	return <ManagerScreen
				title="DriverInsights"
				id="DriverInsightsManager"
				fullModeComponent={<DriverInsightsFilteredGridEditor
										reference="DriverInsightsFilteredGridEditor"
									/>}
				sideModeComponent={<DriverInsightsFilteredSideGridEditor
										reference="DriverInsightsFilteredGridEditor"
									/>}
			/>;
}
