import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { Icon } from 'native-base';

function SvgComponent(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      height={16}
      width={18}
      viewBox="0 0 576 512"
      {...props}
    >
      <Path
        d="M309.5 13.5C305.5 5.2 297.1 0 287.9 0s-17.6 5.2-21.6 13.5l-68.6 141.3-153.2 22.7c-9 1.3-16.5 7.6-19.3 16.3s-.5 18.1 5.9 24.5l111.1 110.1L116 483.9c-1.5 9 2.2 18.1 9.7 23.5s17.3 6 25.3 1.7l137-73.2 137 73.2c8.1 4.3 17.9 3.7 25.3-1.7s11.2-14.5 9.7-23.5l-26.4-155.5 111.2-110.2c6.5-6.4 8.7-15.9 5.9-24.5s-10.3-14.9-19.3-16.3l-153.3-22.6-68.6-141.3zM288 384.7V79.1l52.5 108.1c3.5 7.1 10.2 12.1 18.1 13.3L476.9 218 391 303c-5.5 5.5-8.1 13.3-6.8 21l20.2 119.6-105.2-56.1c-3.5-1.9-7.4-2.8-11.2-2.8z"
      />
    </Icon>
  )
}

export default SvgComponent
