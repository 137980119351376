/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	selectUser,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectIsWaitModalShown,
} from '../models/Slices/DebugSlice';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';

import AddressBook from '@onehat/ui/src/Components/Icons/AddressBook';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import MoneyBill from '@onehat/ui/src/Components/Icons/MoneyBill.js';
import Comments from '@onehat/ui/src/Components/Icons/Comments';
import StarHalfStroke from '../components/Icons/StarHalfStroke.js';
import House from '@onehat/ui/src/Components/Icons/House';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import CarBurst from '../components/Icons/CarBurst.js';


import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import DriverInsightsManager from '../screens/DriverInsightsManager';
import DriverIncentivesManager from '../screens/DriverIncentivesManager';
import DriverInfractionsManager from '../screens/DriverInfractionsManager';
import EngagementsManager from '../screens/EngagementsManager';
import ParsManager from '../screens/ParsManager';
import Home from '../screens/Home';
import Logout from '../screens/Logout';
import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const
	Stack = createStackNavigator(),
	prefix = '/' + AppGlobals.urlPrefix,
	tabs = [
		{
			title: 'Home',
			_icon: { as: House },
			path: prefix + Inflector.dasherize(Inflector.underscore('Home')),
			screenName: 'Home',
		},
		{
			title: 'Driver Incentives',
			_icon: { as: MoneyBill },
			path: prefix + Inflector.dasherize(Inflector.underscore('DriverIncentives')),
			screenName: 'DriverIncentivesManager',
		},
		{
			title: 'Driver Infractions',
			_icon: { as: CarBurst },
			path: prefix + Inflector.dasherize(Inflector.underscore('DriverInfractions')),
			screenName: 'DriverInfractionsManager',
		},
		{
			title: 'Driver Insights',
			_icon: { as: Comments },
			path: prefix + Inflector.dasherize(Inflector.underscore('DriverInsights')),
			screenName: 'DriverInsightsManager',
		},
		{
			title: 'PAR Scores',
			_icon: { as: StarHalfStroke },
			path: prefix + Inflector.dasherize(Inflector.underscore('Pars')),
			screenName: 'ParsManager',
		},
		{
			title: 'Engagements',
			_icon: { as: AddressBook },
			path: prefix + Inflector.dasherize(Inflector.underscore('Engagements')),
			screenName: 'EngagementsManager',
		},
		{
			title: 'Reports',
			_icon: { as: ChartPie },
			path: prefix + Inflector.dasherize(Inflector.underscore('Reports')),
			screenName: 'ReportsManager',
		},
		{
			title: 'Users',
			_icon: { as: User },
			path: prefix + Inflector.dasherize(Inflector.underscore('Users')),
			screenName: 'UsersManager',
		},
		{
			title: 'Groups',
			_icon: { as: UserGroup },
			path: prefix + Inflector.dasherize(Inflector.underscore('Groups')),
			screenName: 'GroupsManager',
		},
		{
			title: 'Logout',
			_icon: { as: RightFromBracket },
			path: prefix + Inflector.dasherize(Inflector.underscore('Logout')),
			screenName: 'Logout',
		},
	];

function AppNavigator(props) {
	const
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		user = useSelector(selectUser),
		[currentTabIx, setCurrentTabIx] = useState(0),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Home'),
		onChangeCurrentTab = (ix) => {
			// update state
			setCurrentTabIx(ix);

			// switch nav
			const
				currentTab = tabs[ix],
				path = currentTab.path;
			linkTo(path);
		};

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(prefix + 'login');
				return;
			}

			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;

			setCurrentTabIx(whichTabIx);
			if (whichTab) {
				setInitialRouteName(whichTab.screenName);
			}
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}


	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'Nu-Way Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};

	return <Row flex={1} w="100%">
				{isWaitModalShown && <WaitMessage />}
				{alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}

				{!!currentTabIx && currentTabIx !== tabs.length -1 && // hide TabBar if showing Home or Logout
					<TabBar
						tabs={tabs}
						direction={VERTICAL}
						tabWidth={180}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={onChangeCurrentTab}
					/>}
				<Stack.Navigator {...navigatorOptions}>
					<Stack.Screen name="Home">
						{() => <Home tabs={tabs} onChangeCurrentTab={onChangeCurrentTab} />}
					</Stack.Screen>
					<Stack.Screen name="DriverInsightsManager" component={DriverInsightsManager} />
					<Stack.Screen name="DriverIncentivesManager" component={DriverIncentivesManager} />
					<Stack.Screen name="DriverInfractionsManager" component={DriverInfractionsManager} />
					<Stack.Screen name="EngagementsManager" component={EngagementsManager} />
					<Stack.Screen name="ParsManager" component={ParsManager} />
					<Stack.Screen name="ReportsManager" component={ReportsManager} />
					<Stack.Screen name="UsersManager" component={UsersManager} />
					<Stack.Screen name="GroupsManager" component={GroupsManager} />
					<Stack.Screen name="Logout" component={Logout} />
				</Stack.Navigator>
			</Row>;
}

export default AppNavigator;

