/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import DriverInfractionsEditorWindow from '../../Window/DriverInfractionsEditorWindow.js';
import DriverInfractionsGridColumns from '../../Grid/Columns/DriverInfractionsGridColumns.js';

export default function DriverInfractionsGridEditorPanel(props) {
	return <GridPanel
				reference="DriverInfractionsGridEditorPanel"
				model="DriverInfractions"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={DriverInfractionsEditorWindow}
				columnsConfig={DriverInfractionsGridColumns}
				
				
				{...props}
			/>;
}