import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Users = {
	
	name: 'Users',
	
	model: {

		idProperty: 'users__id',
		displayProperty: 'users__full_name',
		sortProperty: 'users__username',
		
		sorters: null,

		validator: yup.object({
			users__username: yup.string().max(50).required(),
			users__email: yup.string().max(50).nullable(),
			users__password: yup.string().nullable(),
			users__first_name: yup.string().max(50).nullable(),
			users__last_name: yup.string().max(50).nullable(),
			users__job_title: yup.string().max(50).nullable(),
			users__groups: yup.string().nullable(),
			users__mpp_id: yup.string().max(50).nullable(),
			users__mpp_type: yup.string().max(50).nullable(),
			users__mpp_terminal: yup.string().max(50).nullable(),
			users__mpp_days_since: yup.string().max(50).nullable(),
			users__hire_date: yup.date().nullable(),
			users__date_of_birth: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'users__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'users__username', mapping: 'username', title: 'Username', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__email', mapping: 'email', title: 'Email', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__password', mapping: 'password', title: 'Password', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__full_name', mapping: 'full_name', title: 'Full Name', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'users__first_name', mapping: 'first_name', title: 'First Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__last_name', mapping: 'last_name', title: 'Last Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__is_manager', mapping: 'is_manager', title: 'Is Manager?', type: 'bool', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'users__job_title', mapping: 'job_title', title: 'Job Title', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'users__groups', mapping: 'groups', title: 'Groups', filterType: {"type":"GroupsTag"}, viewerType: {"type":"GroupsTag"}, editorType: {"type":"GroupsTagEditor","loadAfterRender":!1}, fieldGroup: '1. General', },
			{ name: 'users__login_count', mapping: 'login_count', title: 'Login Count', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'users__last_login', mapping: 'last_login', title: 'Last Login', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'users__is_ftp_user', mapping: 'is_ftp_user', title: 'FTP User?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__mpp_id', mapping: 'mpp_id', title: 'MPP', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__mpp_type', mapping: 'mpp_type', title: 'MPP Type', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__mpp_terminal', mapping: 'mpp_terminal', title: 'MPP Terminal', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__mpp_days_since', mapping: 'mpp_days_since', title: 'MPP Days Since', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__hire_date', mapping: 'hire_date', title: 'Hire Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__date_of_birth', mapping: 'date_of_birth', title: 'Date Of Birth', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Nu-Way Specific', },
			{ name: 'users__token', mapping: 'token', title: 'Token', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__token_generated', mapping: 'token_generated', title: 'Token Generated', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__expo_token', mapping: 'expo_token', title: 'Expo Token', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__password_reset', mapping: 'password_reset', title: 'Password Reset', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'pars__id', mapping: 'par.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__user_id', mapping: 'par.user_id', title: 'User', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__hire_date_score', mapping: 'par.hire_date_score', title: 'Hire Date Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__work_shift_score', mapping: 'par.work_shift_score', title: 'Work Shift Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__vehicle_score', mapping: 'par.vehicle_score', title: 'Vehicle Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__route_score', mapping: 'par.route_score', title: 'Route Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__position_risk_score', mapping: 'par.position_risk_score', title: 'Position Risk Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__speeding_days_score', mapping: 'par.speeding_days_score', title: 'Speeding Days Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__move_violations_warning_score', mapping: 'par.move_violations_warning_score', title: 'Move Violations Warning Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__hos_risk', mapping: 'par.hos_risk', title: 'Hos Risk', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__motorist_complaints_score', mapping: 'par.motorist_complaints_score', title: 'Motorist Complaints Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__dash_cam_coaching_score', mapping: 'par.dash_cam_coaching_score', title: 'Dash Cam Coaching Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__preventable_accident_score', mapping: 'par.preventable_accident_score', title: 'Preventable Accident Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__clean_inspection_adj_score', mapping: 'par.clean_inspection_adj_score', title: 'Clean Inspection Adj Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__remedial_training_adj_score', mapping: 'par.remedial_training_adj_score', title: 'Remedial Training Adj Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__behavior_risk', mapping: 'par.behavior_risk', title: 'Behavior Risk', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__positive_adjustments', mapping: 'par.positive_adjustments', title: 'Positive Adjustments', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pars__par_score', mapping: 'par.par_score', title: 'Par Score', type: 'percent', precision: 0, isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasOne: [
				'Pars'
			],
			hasMany: [
				'Permissions',
				'GroupsUsers',
				'DriverInsights',
				'DriverIncentives',
				'DriverInfractions',
				'Engagements'
			],
			belongsToMany: [
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'users__groups',
			// 'users__login_count',
			// 'users__last_login',
			// 'users__is_ftp_user',
			// 'users__hire_date',
			// 'users__date_of_birth',
			// 'users__token_generated'
		],

	},

	entity: {
		methods: {

			getPermissions: function() {
				const Permissions = this.getAssociatedRepository('Permissions');
				return Permissions.getBy((entity) => {
					return entity.permissions__user_id === this.users__id;
				});
			},

			getGroupsUsers: function() {
				const GroupsUsers = this.getAssociatedRepository('GroupsUsers');
				return GroupsUsers.getBy((entity) => {
					return entity.groups_users__user_id === this.users__id;
				});
			},

			getDriverInsights: function() {
				const DriverInsights = this.getAssociatedRepository('DriverInsights');
				return DriverInsights.getBy((entity) => {
					return entity.driver_insights__user_id === this.users__id;
				});
			},

			getDriverIncentives: function() {
				const DriverIncentives = this.getAssociatedRepository('DriverIncentives');
				return DriverIncentives.getBy((entity) => {
					return entity.driver_incentives__user_id === this.users__id;
				});
			},

			getDriverInfractions: function() {
				const DriverInfractions = this.getAssociatedRepository('DriverInfractions');
				return DriverInfractions.getBy((entity) => {
					return entity.driver_infractions__user_id === this.users__id;
				});
			},

			getEngagements: function() {
				const Engagements = this.getAssociatedRepository('Engagements');
				return Engagements.getBy((entity) => {
					return entity.engagements__user_id === this.users__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true
	},

};

export default Users;
