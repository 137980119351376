/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import DriverInsightSubjectsEditorWindow from '../../../Window/DriverInsightSubjectsEditorWindow.js';

function DriverInsightSubjectsTagEditor(props) {
	return <TagEditor
				reference="DriverInsightSubjectsTagEditor"
				model="DriverInsightSubjects"
				uniqueRepository={true}
				Editor={DriverInsightSubjectsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default DriverInsightSubjectsTagEditor;