import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import DriverIncentivesFilteredGridEditor from '../components/Grid/DriverIncentivesFilteredGridEditor.js';
import DriverIncentivesFilteredSideGridEditor from '../components/Grid/DriverIncentivesFilteredSideGridEditor.js';

export default function DriverIncentivesManager(props) {
	return <ManagerScreen
				title="DriverIncentives"
				id="DriverIncentivesManager"
				fullModeComponent={<DriverIncentivesFilteredGridEditor
										reference="DriverIncentivesFilteredGridEditor"
									/>}
				sideModeComponent={<DriverIncentivesFilteredSideGridEditor
										reference="DriverIncentivesFilteredGridEditor"
									/>}
			/>;
}
