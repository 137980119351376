export default [
	    {
	        "id": "driver_infractions__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300, // MOD
	        "editField": "driver_infractions__user_id"
	    },
	    {
	        "id": "driver_infractions__period",
	        "header": "Period",
	        "fieldName": "driver_infractions__period",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_infractions__infraction_date",
	        "header": "Infraction Date",
	        "fieldName": "driver_infractions__infraction_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "driver_infractions__category",
	        "header": "Category",
	        "fieldName": "driver_infractions__category",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];