/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverInsightSubjectsGridEditorPanel from './DriverInsightSubjectsGridEditor.js';

export default function DriverInsightSubjectsFilteredGridEditorPanel(props) {
	return <DriverInsightSubjectsGridEditorPanel
				reference="DriverInsightSubjectsFilteredGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}