/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import DriverIncentivesEditor from '../../Editor/DriverIncentivesEditor.js';
import DriverIncentivesGridColumns from '../../Grid/Columns/DriverIncentivesGridColumns.js';

export default function DriverIncentivesSideGridEditorPanel(props) {
	return <GridPanel
				reference="DriverIncentivesSideGridEditorPanel"
				model="DriverIncentives"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={DriverIncentivesEditor}
				columnsConfig={DriverIncentivesGridColumns}
				
				
				{...props}
			/>;
}