/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function ParsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "pars__user_id"
		                    },
		                    {
		                        "name": "pars__hire_date_score"
		                    },
		                    {
		                        "name": "pars__work_shift_score"
		                    },
		                    {
		                        "name": "pars__vehicle_score"
		                    },
		                    {
		                        "name": "pars__route_score"
		                    },
		                    {
		                        "name": "pars__position_risk_score"
		                    },
		                    {
		                        "name": "pars__speeding_days_score"
		                    },
		                    {
		                        "name": "pars__move_violations_warning_score"
		                    },
		                    {
		                        "name": "pars__hos_risk"
		                    },
		                    {
		                        "name": "pars__motorist_complaints_score"
		                    },
		                    {
		                        "name": "pars__dash_cam_coaching_score"
		                    },
		                    {
		                        "name": "pars__preventable_accident_score"
		                    },
		                    {
		                        "name": "pars__clean_inspection_adj_score"
		                    },
		                    {
		                        "name": "pars__remedial_training_adj_score"
		                    },
		                    {
		                        "name": "pars__behavior_risk"
		                    },
		                    {
		                        "name": "pars__positive_adjustments"
		                    },
		                    {
		                        "name": "pars__par_score"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="ParsEditor"
				title="Pars"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

