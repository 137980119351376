export default [
	    {
	        "id": "groups_users__group_id",
	        "header": "Group",
	        "fieldName": "groups__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "groups_users__group_id"
	    },
	    {
	        "id": "groups_users__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "groups_users__user_id"
	    }
	];