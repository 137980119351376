/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import GlobalValuesEditorWindow from '../../Window/GlobalValuesEditorWindow.js';
import GlobalValuesGridColumns from '../../Grid/Columns/GlobalValuesGridColumns.js';

export default function GlobalValuesGridEditorPanel(props) {
	return <GridPanel
				reference="GlobalValuesGridEditorPanel"
				model="GlobalValues"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={GlobalValuesEditorWindow}
				columnsConfig={GlobalValuesGridColumns}
				
				
				{...props}
			/>;
}