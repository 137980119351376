/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ParsGridColumns from './Columns/ParsGridColumns.js';

export default function ParsGrid(props) {
	return <Grid
				reference="ParsGrid"
				model="Pars"
				usePermissions={true}
				columnsConfig={ParsGridColumns}

				{...props}
			/>;
}