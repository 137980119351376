export default [
	    {
	        "id": "driver_incentives__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "driver_incentives__user_id"
	    },
	    {
	        "id": "driver_incentives__period",
	        "header": "Period",
	        "fieldName": "driver_incentives__period",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__attendance",
	        "header": "Attendance",
	        "fieldName": "driver_incentives__attendance",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__accident",
	        "header": "Accident",
	        "fieldName": "driver_incentives__accident",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__par",
	        "header": "Par",
	        "fieldName": "driver_incentives__par",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__safety",
	        "header": "Safety",
	        "fieldName": "driver_incentives__safety",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__perfect_achievement",
	        "header": "Perfect Achievement",
	        "fieldName": "driver_incentives__perfect_achievement",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_incentives__ytd_total",
	        "header": "Ytd Total",
	        "fieldName": "driver_incentives__ytd_total",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];