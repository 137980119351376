/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import GlobalValuesEditorWindow from '../../../Window/GlobalValuesEditorWindow.js';

function GlobalValuesTag(props) {
	return <Tag
				reference="GlobalValuesTag"
				model="GlobalValues"
				uniqueRepository={true}
				Editor={GlobalValuesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default GlobalValuesTag;