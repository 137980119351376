/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function UsersEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "users__username"
		                    },
		                    {
		                        "name": "users__email"
		                    },
		                    {
		                        "name": "users__password"
		                    },
		                    {
		                        "name": "users__full_name"
		                    },
		                    {
		                        "name": "users__first_name"
		                    },
		                    {
		                        "name": "users__last_name"
		                    },
		                    {
		                        "name": "users__is_manager"
		                    },
		                    {
		                        "name": "users__job_title"
		                    },
		                    {
		                        "name": "users__groups"
		                    },
		                    {
		                        "name": "users__login_count",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "users__last_login",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Nu-Way Specific",
		                "reference": "nu_way_specific",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "users__mpp_id"
		                    },
		                    {
		                        "name": "users__mpp_type"
		                    },
		                    {
		                        "name": "users__mpp_terminal"
		                    },
		                    {
		                        "name": "users__mpp_days_since"
		                    },
		                    {
		                        "name": "users__hire_date"
		                    },
		                    {
		                        "name": "users__date_of_birth"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Permissions",
		    //     "type": "PermissionsGridEditor",
		    //     "selectorId": "permissions__user_id"
		    // },
		    {
		        "title": "Driver Insights",
		        "type": "DriverInsightsGridEditor",
		        "selectorId": "driver_insights__user_id"
		    },
		    {
		        "title": "Driver Incentives",
		        "type": "DriverIncentivesGridEditor",
		        "selectorId": "driver_incentives__user_id"
		    },
		    {
		        "title": "Driver Infractions",
		        "type": "DriverInfractionsGridEditor",
		        "selectorId": "driver_infractions__user_id"
		    },
		    {
		        "title": "Engagements",
		        "type": "EngagementsGridEditor",
		        "selectorId": "engagements__user_id"
		    }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="UsersEditor"
				title="Users"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

