import * as React from "react"
import Svg, { Defs, G, Path } from "react-native-svg"
import { Icon } from 'native-base';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Icon
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 780.1 409.19"
      {...props}
    >
      <Defs></Defs>
      <G id="Layer_1-2">
        <Path
          className="cls-1"
          d="M334.4 254.51h-30.78V143.04h-75.43L167.12 0H0v334.4h143.04c-.64 3.51-.96 7.02-.96 10.69 0 35.4 28.86 64.11 64.11 64.11s64.11-28.7 64.11-64.11c0-3.67-.32-7.18-.96-10.69h65.06v-79.89zM114.02 147.67l-21.85 29.66h-59.8V32.37h114.18l49.12 115.29h-81.65zm140.33 197.42c0 26.63-21.53 48.16-48.16 48.16s-48.16-21.53-48.16-48.16c0-3.67.48-7.18 1.28-10.69 4.78-21.53 24.08-37.47 46.88-37.47s42.1 15.95 46.88 37.47c.8 3.51 1.28 7.02 1.28 10.69zM726.41 267.75h-51.66a8.67 8.67 0 01-7.5-4.31l-57.33-98.53a8.67 8.67 0 00-7.5-4.31H453.15a8.68 8.68 0 00-7.86 5l-40.93 87.43a8.68 8.68 0 01-7.86 5h-37.63c-4.79 0-8.68 3.89-8.68 8.68v90.6c0 4.79 3.89 8.68 8.68 8.68h37.73c4.15 24.4 25.52 43.06 51.03 43.06s46.88-18.66 51.03-43.06h131.4c4.15 24.4 25.51 43.06 51.03 43.06s46.88-18.66 51.03-43.06h47.99v-41.23c0-2.2-.83-4.31-2.33-5.92l-45.01-48.32a8.679 8.679 0 00-6.35-2.76zm-240.99 98.23c-3.83 17.22-19.3 30.14-37.79 30.14s-33.97-12.92-37.79-30.14c-1.33-4.77-1.33-9.54-.74-15.05 1.24-11.59 8.02-22.3 18.41-27.59 29.67-15.1 59.04 5.88 59.04 33.87 0 3.03-.32 5.9-1.12 8.77zm195.67 30.14c-18.34 0-33.81-12.92-37.79-30.14-.8-2.87-1.12-5.74-1.12-8.77 0-25.94 25.47-45.86 52.42-36.6 15.18 5.22 25.35 20.42 25.4 36.47 0 3.08-.31 5.99-1.12 8.91-3.83 17.22-19.3 30.14-37.79 30.14z"
        />
        <Path
          className="cls-1"
          transform="rotate(-2.38 309.872 95.054)"
          d="M303.4 63.17H315.37V126.43H303.4z"
        />
        <Path
          className="cls-1"
          transform="rotate(-56.95 412.987 94.794)"
          d="M339.69 88.82H486.25V100.78H339.69z"
        />
        <Path
          className="cls-1"
          d="M336.33 64.07H348.28999999999996V222.07H336.33z"
        />
        <Path
          className="cls-1"
          transform="rotate(-50.44 391.93 203.209)"
          d="M352.62 197.23H431.19V209.19H352.62z"
        />
      </G>
    </Icon>
  )
}

export default SvgComponent
