/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EngagementTypesEditor from '../Editor/EngagementTypesEditor.js';
import EngagementTypesGridColumns from './Columns/EngagementTypesGridColumns.js';

export default function EngagementTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="EngagementTypesSideGridEditor"
				model="EngagementTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={EngagementTypesEditor}
				columnsConfig={EngagementTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}