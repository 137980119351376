import * as yup from 'yup'; // https://github.com/jquense/yup#string

const DriverInsights = {
	
	name: 'DriverInsights',
	
	model: {

		idProperty: 'driver_insights__id',
		displayProperty: 'driver_insights__id',
		sortProperty: 'driver_insights__submitted_date',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			driver_insights__user_id: yup.number().integer().required(),
			driver_insights__driver_insight_subject_id: yup.number().integer().required(),
			driver_insights__comments: yup.string().required()
		}),
		
		properties: [
			{ name: 'driver_insights__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insights__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'driver_insights__driver_insight_subject_id', mapping: 'driver_insight_subject_id', title: 'Driver Insight Subject', type: 'int', isFk: true, fkIdField: 'driver_insight_subjects__id', fkDisplayField: 'driver_insight_subjects__name', filterType: {"type":"DriverInsightSubjectsCombo","loadAfterRender":!1}, editorType: {"type":"DriverInsightSubjectsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'driver_insights__uuid', mapping: 'uuid', title: 'Uuid', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insights__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'driver_insights__submitted_date', mapping: 'submitted_date', title: 'Submitted Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insights__has_ftped', mapping: 'has_ftped', title: 'Has FTPed', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insights__has_attachments', mapping: 'has_attachments', title: 'Has Attachments', type: 'bool', isVirtual: true, isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_manager', mapping: 'user.is_manager', title: 'Is Manager?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_ftp_user', mapping: 'user.is_ftp_user', title: 'FTP User?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_id', mapping: 'user.mpp_id', title: 'MPP', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_type', mapping: 'user.mpp_type', title: 'MPP Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_terminal', mapping: 'user.mpp_terminal', title: 'MPP Terminal', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_days_since', mapping: 'user.mpp_days_since', title: 'MPP Days Since', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__hire_date', mapping: 'user.hire_date', title: 'Hire Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__date_of_birth', mapping: 'user.date_of_birth', title: 'Date Of Birth', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'driver_insight_subjects__id', mapping: 'driver_insight_subject.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'driver_insight_subjects__sort_order', mapping: 'driver_insight_subject.sort_order', title: 'Sort', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'driver_insight_subjects__name', mapping: 'driver_insight_subject.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'DriverInsightSubjects'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			'driver_insights__user_id',
			// 'driver_insights__driver_insight_subject_id',
			'driver_insights__submitted_date',
			// 'driver_insights__has_ftped'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.driver_insights__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.driver_insights__user_id);
				}
				return entity;
			},

			getDriverInsightSubject: async function() {
				const DriverInsightSubjects = this.getAssociatedRepository('DriverInsightSubjects');
				let entity = DriverInsightSubjects.getById(this.driver_insights__driver_insight_subject_id);
				if (!entity) {
					entity = await DriverInsightSubjects.getSingleEntityFromServer(this.driver_insights__driver_insight_subject_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default DriverInsights;
