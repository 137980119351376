/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import DriverIncentivesEditorWindow from '../Window/DriverIncentivesEditorWindow.js';
import DriverIncentivesGridColumns from './Columns/DriverIncentivesGridColumns.js';

export default function DriverIncentivesGridEditor(props) {
	return <WindowedGridEditor
				reference="DriverIncentivesGridEditor"
				model="DriverIncentives"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={DriverIncentivesEditorWindow}
				columnsConfig={DriverIncentivesGridColumns}
				
				
				{...props}
			/>;
}