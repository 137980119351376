/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import {
	Platform,
} from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Login from '../screens/Login';
import NotFound from '../screens/NotFound';
// import Announcements from '../screens/Announcements';
import AppNavigator from './AppNavigator';

// NOTE: All of these separate navigators are actually required.
// I made it, then later tried to refactor it in a single source file.
// Then I realized all of them need to actually be there.

let Stack;
switch(Platform.OS) {
	// case 'ios':
	// 	Stack = createStackNavigator(); // When using createStackNavigator, Android sometimes "twitches," so we can't use it for Android
	// 	break;
	// case 'android':
	// 	Stack = createNativeStackNavigator(); // When using createNativeStackNavigator, iOS renders NativeBase select elements under iOS modal, so we can't use it for iOS
	// 	break;
	case 'web':
		Stack = createNativeStackNavigator();
		break;

}

const
	navigatorOptions = {
		initialRouteName: 'Login',
		screenOptions: {
			headerBackTitle: 'Back',
			headerMode: 'float',
			headerShown: false,
		},
	};

export default function Authenticator(props) {
	return <Stack.Navigator {...navigatorOptions}>
				<Stack.Screen name="Login" component={Login} options={{ title: 'Nu-Way Admin App: Login' }} />
				{/* <Stack.Screen name="Announcements" component={Announcements} options={{ headerShown: true }} /> */}
				<Stack.Screen name="AppNavigator" component={AppNavigator} />
				<Stack.Screen name="NotFound" component={NotFound} options={{ title: 'Nu-Way Admin App: Error 404' }} />
			</Stack.Navigator>;
}