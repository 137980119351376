import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Pars = {
	
	name: 'Pars',
	
	model: {

		idProperty: 'pars__id',
		displayProperty: 'pars__id',
		sortProperty: 'pars__id',
		
		sorters: null,

		validator: yup.object({
			pars__user_id: yup.number().integer().nullable(),
			pars__hire_date_score: yup.number().required(),
			pars__work_shift_score: yup.number().required(),
			pars__vehicle_score: yup.number().required(),
			pars__route_score: yup.number().required(),
			pars__position_risk_score: yup.number().required(),
			pars__speeding_days_score: yup.number().required(),
			pars__move_violations_warning_score: yup.number().required(),
			pars__hos_risk: yup.number().required(),
			pars__motorist_complaints_score: yup.number().required(),
			pars__dash_cam_coaching_score: yup.number().required(),
			pars__preventable_accident_score: yup.number().required(),
			pars__clean_inspection_adj_score: yup.number().required(),
			pars__remedial_training_adj_score: yup.number().required(),
			pars__behavior_risk: yup.number().required(),
			pars__positive_adjustments: yup.number().required(),
			pars__par_score: yup.number().required()
		}),
		
		properties: [
			{ name: 'pars__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'pars__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'pars__hire_date_score', mapping: 'hire_date_score', title: 'Hire Date Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__work_shift_score', mapping: 'work_shift_score', title: 'Work Shift Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__vehicle_score', mapping: 'vehicle_score', title: 'Vehicle Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__route_score', mapping: 'route_score', title: 'Route Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__position_risk_score', mapping: 'position_risk_score', title: 'Position Risk Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__speeding_days_score', mapping: 'speeding_days_score', title: 'Speeding Days Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__move_violations_warning_score', mapping: 'move_violations_warning_score', title: 'Move Violations Warning Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__hos_risk', mapping: 'hos_risk', title: 'Hos Risk', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__motorist_complaints_score', mapping: 'motorist_complaints_score', title: 'Motorist Complaints Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__dash_cam_coaching_score', mapping: 'dash_cam_coaching_score', title: 'Dash Cam Coaching Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__preventable_accident_score', mapping: 'preventable_accident_score', title: 'Preventable Accident Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__clean_inspection_adj_score', mapping: 'clean_inspection_adj_score', title: 'Clean Inspection Adj Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__remedial_training_adj_score', mapping: 'remedial_training_adj_score', title: 'Remedial Training Adj Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__behavior_risk', mapping: 'behavior_risk', title: 'Behavior Risk', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__positive_adjustments', mapping: 'positive_adjustments', title: 'Positive Adjustments', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'pars__par_score', mapping: 'par_score', title: 'Par Score', type: 'percent', precision: 0, filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_manager', mapping: 'user.is_manager', title: 'Is Manager?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_ftp_user', mapping: 'user.is_ftp_user', title: 'FTP User?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_id', mapping: 'user.mpp_id', title: 'MPP', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_type', mapping: 'user.mpp_type', title: 'MPP Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_terminal', mapping: 'user.mpp_terminal', title: 'MPP Terminal', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_days_since', mapping: 'user.mpp_days_since', title: 'MPP Days Since', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__hire_date', mapping: 'user.hire_date', title: 'Hire Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__date_of_birth', mapping: 'user.date_of_birth', title: 'Date Of Birth', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'pars__user_id',
			// 'pars__hire_date_score',
			// 'pars__work_shift_score',
			// 'pars__vehicle_score',
			// 'pars__route_score',
			// 'pars__position_risk_score',
			// 'pars__speeding_days_score',
			// 'pars__move_violations_warning_score',
			// 'pars__hos_risk',
			// 'pars__motorist_complaints_score',
			// 'pars__dash_cam_coaching_score',
			// 'pars__preventable_accident_score',
			// 'pars__clean_inspection_adj_score',
			// 'pars__remedial_training_adj_score',
			// 'pars__behavior_risk',
			// 'pars__positive_adjustments',
			// 'pars__par_score'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.pars__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.pars__user_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Pars;
