/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ParsEditorWindow from '../Window/ParsEditorWindow.js';
import ParsGridColumns from './Columns/ParsGridColumns.js';

export default function ParsGridEditor(props) {
	return <WindowedGridEditor
				reference="ParsGridEditor"
				model="Pars"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ParsEditorWindow}
				columnsConfig={ParsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}