/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import GlobalValuesGridColumns from '../../Grid/Columns/GlobalValuesGridColumns.js';

export default function GlobalValuesGridPanel(props) {
	return <GridPanel
				reference="GlobalValuesGridPanel"
				model="GlobalValues"
				usePermissions={true}
				columnsConfig={GlobalValuesGridColumns}

				{...props}
			/>;
}