/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverInsightsInlineGridEditorPanel from './DriverInsightsInlineGridEditor.js';

export default function DriverInsightsFilteredInlineGridEditorPanel(props) {
	return <DriverInsightsInlineGridEditorPanel
				reference="DriverInsightsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}