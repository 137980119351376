import { useState, } from 'react';
import {
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import AddressBook from '@onehat/ui/src/Components/Icons/AddressBook';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import Comments from '@onehat/ui/src/Components/Icons/Comments';
import MoneyBill from '@onehat/ui/src/Components/Icons/MoneyBill.js';
import StarHalfStroke from '../components/Icons/StarHalfStroke.js';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import CarBurst from '../components/Icons/CarBurst.js';
import appPng from '../components/Images/NuWayLogo.png';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';

function chunkArray(array, chunkSize) {
	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}
	return result;
}

function Box(props) {
	const {
			url,
			text,
			icon,
			onNavigate,
		} = props,
		[containerWidth, setContainerWidth] = useState(100),
		[isRendered, setIsRendered] = useState(false),
		onPress = () => {
			onNavigate(url);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	let fontSize = '15px',
		iconSize = '40px';
	if (containerWidth > 200) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 300) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 400) {
		fontSize = '35px';
		iconSize = '65px';
	}
	if (containerWidth > 500) {
		fontSize = '25px';
		iconSize = '65px';
	}

	return <Pressable
				{...testProps(url)}
				onLayout={onLayout} 
				onPress={onPress} 
				flex={1}
				_hover={{ bg: "primary.300" }}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				bg="primary.700"
				p={5}
				mx={2}
				borderRadius={10}
			>
				{isRendered &&
					<>
						<Icon
							as={icon}
							color="#fff"
							size={iconSize}
							minHeight={5}
							mb={2}
						/>
						<Text
							fontSize={fontSize}
							lineHeight={fontSize}
							style={{
								fontFamily: 'OpenSansCondensed_700Bold',
							}}
							color="#fff"
							textAlign="center"
						>{text}</Text>
					</>}
			</Pressable>;
}

export default function Home(props) {
	const {
			tabs,
			onChangeCurrentTab,
		} = props,
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Home',
		[containerWidth, setContainerWidth] = useState(500),
		[isRendered, setIsRendered] = useState(false),
		onNavigate = (path) => {
			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;
			onChangeCurrentTab(whichTabIx);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	if (!isActive) {
		return null;
	}

	const
		urlPrefix = '/' + AppGlobals.urlPrefix,
		rows = [],
		items = [

			<Box
				text="Driver Incentives"
				key="DriverIncentives"
				icon={MoneyBill}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('DriverIncentives'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Driver Infractions"
				key="DriverInfractions"
				icon={CarBurst}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('DriverInfractions'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Driver Insights"
				key="DriverInsights"
				icon={Comments}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('DriverInsights'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="PAR Scores"
				key="ParScores"
				icon={StarHalfStroke}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Pars'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Engagements"
				key="Engagements"
				icon={AddressBook}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Engagements'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Reports"
				key="Reports"
				icon={ChartPie}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Reports'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Users"
				key="Users"
				icon={User}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Users'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Groups"
				key="Groups"
				icon={UserGroup}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Groups'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Logout"
				key="Logout"
				icon={RightFromBracket}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Logout'))}
				onNavigate={onNavigate}
			/>,
		];
	
	let itemsPerRow = 1;
	if (containerWidth > 400) {
		itemsPerRow = 2;
	}
	if (containerWidth > 600) {
		itemsPerRow = 3;
	}
	if (containerWidth > 800) {
		itemsPerRow = 4;
	}

	const chunks = chunkArray(items, itemsPerRow);
	_.each(chunks, (items, ix) => {
		rows.push(<Row key={'row' + ix} flex={1} justifyContent="space-between" mb={3}>{items}</Row>);
	});
	
	return <Column flex={1} w="100%" p={10} onLayout={onLayout} >
				{isRendered && 
					<>
						<Column flex={3} pb={5}>
							<Image
								source={appPng}
								margin="auto"
								resizeMode="contain"
								alt="App Logo"
								flex={1}
								w="100%"
							/>
						</Column>
						<Column flex={5}>
							{rows}
						</Column>
					</>}
			</Column>;

}
