import * as yup from 'yup'; // https://github.com/jquense/yup#string

const DriverInsightSubjects = {
	
	name: 'DriverInsightSubjects',
	
	model: {

		idProperty: 'driver_insight_subjects__id',
		displayProperty: 'driver_insight_subjects__name',
		sortProperty: 'driver_insight_subjects__sort_order',
		
		sorters: null,

		validator: yup.object({
			driver_insight_subjects__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'driver_insight_subjects__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insight_subjects__sort_order', mapping: 'sort_order', title: 'Sort', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_insight_subjects__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'DriverInsights'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'driver_insight_subjects__sort_order'
		],

	},

	entity: {
		methods: {

			getDriverInsights: function() {
				const DriverInsights = this.getAssociatedRepository('DriverInsights');
				return DriverInsights.getBy((entity) => {
					return entity.driver_insights__driver_insight_subject_id === this.driver_insight_subjects__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": true
	},

};

export default DriverInsightSubjects;
