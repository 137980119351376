/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import DriverInsightSubjectsEditor from '../Editor/DriverInsightSubjectsEditor.js';
import DriverInsightSubjectsGridColumns from './Columns/DriverInsightSubjectsGridColumns.js';

export default function DriverInsightSubjectsSideGridEditor(props) {
	return <SideGridEditor
				reference="DriverInsightSubjectsSideGridEditor"
				model="DriverInsightSubjects"
				usePermissions={true}
				isCollapsible={false}
				Editor={DriverInsightSubjectsEditor}
				columnsConfig={DriverInsightSubjectsGridColumns}
				canRowsReorder={true}
				
				{...props}
			/>;
}