/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import DriverInsightSubjectsGridColumns from '../../Grid/Columns/DriverInsightSubjectsGridColumns.js';

export default function DriverInsightSubjectsGridPanel(props) {
	return <GridPanel
				reference="DriverInsightSubjectsGridPanel"
				model="DriverInsightSubjects"
				usePermissions={true}
				columnsConfig={DriverInsightSubjectsGridColumns}
				canRowsReorder={true}
				{...props}
			/>;
}