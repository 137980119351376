/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Attachments from './Attachments.js';
import DriverIncentives from './DriverIncentives.js';
import DriverInfractions from './DriverInfractions.js';
import DriverInsights from './DriverInsights.js';
import DriverInsightSubjects from './DriverInsightSubjects.js';
import Engagements from './Engagements.js';
import EngagementTypes from './EngagementTypes.js';
import GlobalValues from './GlobalValues.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import Local from './Local.js';
import Pars from './Pars.js';
import Permissions from './Permissions.js';
import Prefs from './Prefs.js';
import Secure from './Secure.js';
import Users from './Users.js';

const obj = {
	Attachments,
	DriverIncentives,
	DriverInfractions,
	DriverInsights,
	DriverInsightSubjects,
	Engagements,
	EngagementTypes,
	GlobalValues,
	Groups,
	GroupsUsers,
	Local,
	Pars,
	Permissions,
	Prefs,
	Secure,
	Users,
};
export default obj;