/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import DriverInsightsGridColumns from './Columns/DriverInsightsGridColumns.js';

export default function DriverInsightsGrid(props) {
	return <Grid
				reference="DriverInsightsGrid"
				model="DriverInsights"
				usePermissions={true}
				columnsConfig={DriverInsightsGridColumns}

				{...props}
			/>;
}