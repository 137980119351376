import { configureStore } from '@reduxjs/toolkit'
import AppReducer from './Slices/AppSlice';
import DebugReducer, * as DebugReducerExports from './Slices/DebugSlice.js';
// import { reducer as NetworkReducer } from 'react-native-offline';
import AppGlobals from '../AppGlobals.js';

const Store = configureStore({
	reducer: {
		app: AppReducer,
		debug: DebugReducer,
		// network: NetworkReducer,
	},

});

AppGlobals.redux = Store; // needed for some OneHat UI components
AppGlobals.debugReducer = DebugReducerExports; // needed for some OneHat UI components

export default Store;
