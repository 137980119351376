export default [
	    {
	        "id": "driver_insight_subjects__sort_order",
	        "header": "Sort",
	        "fieldName": "driver_insight_subjects__sort_order",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "driver_insight_subjects__name",
	        "header": "Name",
	        "fieldName": "driver_insight_subjects__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];