/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GlobalValuesGrid from './GlobalValuesGrid.js';

export default function GlobalValuesFilteredGrid(props) {
	return <GlobalValuesGrid
				reference="GlobalValuesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}