export default [
	    {
	        "id": "driver_insights__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "driver_insights__user_id"
	    },
	    {
	        "id": "driver_insights__driver_insight_subject_id",
	        "header": "Driver Insight Subject",
	        "fieldName": "driver_insight_subjects__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "driver_insights__driver_insight_subject_id"
	    },
	    {
	        "id": "driver_insights__comments",
	        "header": "Comments",
	        "fieldName": "driver_insights__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 300 // MOD
	    },
	    {
	        "id": "driver_insights__submitted_date",
	        "header": "Submitted Date",
	        "fieldName": "driver_insights__submitted_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "driver_insights__has_attachments",
	        "header": "Has Attachments",
	        "fieldName": "driver_insights__has_attachments",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];