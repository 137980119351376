import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import EngagementsFilteredGridEditor from '../components/Grid/EngagementsFilteredGridEditor.js';
import EngagementsFilteredSideGridEditor from '../components/Grid/EngagementsFilteredSideGridEditor.js';

export default function EngagementsManager(props) {
	return <ManagerScreen
				title="Engagements"
				id="EngagementsManager"
				fullModeComponent={<EngagementsFilteredGridEditor
										reference="EngagementsFilteredGridEditor"
									/>}
				sideModeComponent={<EngagementsFilteredSideGridEditor
										reference="EngagementsFilteredGridEditor"
									/>}
			/>;
}
