export default [
	    {
	        "id": "groups_users__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "groups_users__user_id",
	        "flex": 1,
	        "minWidth": 150
	    }
	];