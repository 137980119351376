import Report from '@onehat/ui/src/Components/Report/Report.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Globals from '../../AppGlobals.js';

function ReportComponent(props) {
	const {
			self,
		} = props;
	return <Report
				title="Detailed Driver Engagement"
				description="Provides detailed insights into Driver engagement"
				reportId={3}
				_form={{
					items: [
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'startDate',
									type: 'Date',
									label: 'Start Date',
									allowBlank: true,
									tooltip: 'Earliest date to display',
								},
								{
									name: 'endDate',
									type: 'Date',
									label: 'End Date',
									allowBlank: true,
									tooltip: 'Latest date to display',
								},
							]
						},
						{
							"type": "Column",
							"flex": 1,
							"defaults": {},
							"items": [
								{
									name: 'user_id',
									type: 'UsersCombo',
									label: 'User',
									allowBlank: true,
									tooltip: 'Optionally limit to one user',
								},
							]
						},
					],
					startingValues: {
						startDate: Globals.dates.startOfThisMonth,
						endDate: Globals.dates.today,
						user_id: null,
					},
				}}
				parent={self}
			/>;
}

function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="report3"
					{...props}
				/>;
	};
}
export default withAdditionalProps(withComponent(ReportComponent));
