export default [
	    // {
	    //     "id": "permissions__user_id",
	    //     "header": "User",
	    //     "fieldName": "users__full_name",
	    //     "isSortable": false,
	    //     "isEditable": false,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "permissions__user_id"
	    // },
	    // {
	    //     "id": "permissions__group_id",
	    //     "header": "Group",
	    //     "fieldName": "groups__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "permissions__group_id"
	    // },
	    {
	        "id": "permissions__view_reports",
	        "header": "View Reports",
	        "fieldName": "permissions__view_reports",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_dashboard",
	        "header": "View Dashboard",
	        "fieldName": "permissions__view_dashboard",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_attachments",
	        "header": "View Attachments",
	        "fieldName": "permissions__view_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_attachments",
	        "header": "Add Attachments",
	        "fieldName": "permissions__add_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_attachments",
	        "header": "Edit Attachments",
	        "fieldName": "permissions__edit_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_attachments",
	        "header": "Delete Attachments",
	        "fieldName": "permissions__delete_attachments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_groups",
	        "header": "View Groups",
	        "fieldName": "permissions__view_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_driver_incentives",
	        "header": "View Driver Incentive",
	        "fieldName": "permissions__view_driver_incentives",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_driver_incentives",
	        "header": "Add Driver Incentive",
	        "fieldName": "permissions__add_driver_incentives",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_driver_incentives",
	        "header": "Edit Driver Incentive",
	        "fieldName": "permissions__edit_driver_incentives",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_driver_incentives",
	        "header": "Delete Driver Incentive",
	        "fieldName": "permissions__delete_driver_incentives",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_driver_infractions",
	        "header": "View Driver Infraction",
	        "fieldName": "permissions__view_driver_infractions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_driver_infractions",
	        "header": "Add Driver Infraction",
	        "fieldName": "permissions__add_driver_infractions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_driver_infractions",
	        "header": "Edit Driver Infraction",
	        "fieldName": "permissions__edit_driver_infractions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_driver_infractions",
	        "header": "Delete Driver Infraction",
	        "fieldName": "permissions__delete_driver_infractions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_engagement_types",
	        "header": "View Engagement Types",
	        "fieldName": "permissions__view_engagement_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_engagement_types",
	        "header": "Add Engagement Types",
	        "fieldName": "permissions__add_engagement_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_engagement_types",
	        "header": "Edit Engagement Types",
	        "fieldName": "permissions__edit_engagement_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_engagement_types",
	        "header": "Delete Engagement Types",
	        "fieldName": "permissions__delete_engagement_types",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_engagements",
	        "header": "View Engagements",
	        "fieldName": "permissions__view_engagements",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_engagements",
	        "header": "Add Engagements",
	        "fieldName": "permissions__add_engagements",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_engagements",
	        "header": "Edit Engagements",
	        "fieldName": "permissions__edit_engagements",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_engagements",
	        "header": "Delete Engagements",
	        "fieldName": "permissions__delete_engagements",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_global_values",
	        "header": "View Global Value",
	        "fieldName": "permissions__view_global_values",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_global_values",
	        "header": "Add Global Value",
	        "fieldName": "permissions__add_global_values",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_global_values",
	        "header": "Edit Global Value",
	        "fieldName": "permissions__edit_global_values",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_global_values",
	        "header": "Delete Global Value",
	        "fieldName": "permissions__delete_global_values",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_groups",
	        "header": "Add Groups",
	        "fieldName": "permissions__add_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_groups",
	        "header": "Edit Groups",
	        "fieldName": "permissions__edit_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_groups",
	        "header": "Delete Groups",
	        "fieldName": "permissions__delete_groups",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_groups_users",
	        "header": "View Groups Users",
	        "fieldName": "permissions__view_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_groups_users",
	        "header": "Add Groups Users",
	        "fieldName": "permissions__add_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_groups_users",
	        "header": "Edit Groups Users",
	        "fieldName": "permissions__edit_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_groups_users",
	        "header": "Delete Groups Users",
	        "fieldName": "permissions__delete_groups_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_users",
	        "header": "View Users",
	        "fieldName": "permissions__view_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_users",
	        "header": "Add Users",
	        "fieldName": "permissions__add_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_users",
	        "header": "Edit Users",
	        "fieldName": "permissions__edit_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_users",
	        "header": "Delete Users",
	        "fieldName": "permissions__delete_users",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_permissions",
	        "header": "View Permissions",
	        "fieldName": "permissions__view_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_permissions",
	        "header": "Add Permissions",
	        "fieldName": "permissions__add_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_permissions",
	        "header": "Edit Permissions",
	        "fieldName": "permissions__edit_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_permissions",
	        "header": "Delete Permissions",
	        "fieldName": "permissions__delete_permissions",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_pars",
	        "header": "View Pars",
	        "fieldName": "permissions__view_pars",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_pars",
	        "header": "Add Pars",
	        "fieldName": "permissions__add_pars",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_pars",
	        "header": "Edit Pars",
	        "fieldName": "permissions__edit_pars",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_pars",
	        "header": "Delete Pars",
	        "fieldName": "permissions__delete_pars",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_driver_insights",
	        "header": "View Driver Insights",
	        "fieldName": "permissions__view_driver_insights",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_driver_insights",
	        "header": "Add Driver Insights",
	        "fieldName": "permissions__add_driver_insights",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_driver_insights",
	        "header": "Edit Driver Insights",
	        "fieldName": "permissions__edit_driver_insights",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_driver_insights",
	        "header": "Delete Driver Insights",
	        "fieldName": "permissions__delete_driver_insights",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__view_driver_insight_subjects",
	        "header": "View Driver Insight Subjects",
	        "fieldName": "permissions__view_driver_insight_subjects",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__add_driver_insight_subjects",
	        "header": "Add Driver Insight Subjects",
	        "fieldName": "permissions__add_driver_insight_subjects",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__edit_driver_insight_subjects",
	        "header": "Edit Driver Insight Subjects",
	        "fieldName": "permissions__edit_driver_insight_subjects",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "permissions__delete_driver_insight_subjects",
	        "header": "Delete Driver Insight Subjects",
	        "fieldName": "permissions__delete_driver_insight_subjects",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];