import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Engagements = {
	
	name: 'Engagements',
	
	model: {

		idProperty: 'engagements__id',
		displayProperty: 'engagements__id',
		sortProperty: 'engagements__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			engagements__datetime: yup.date().required(),
			engagements__engagement_type_id: yup.number().integer().required(),
			engagements__user_id: yup.number().integer().required(),
			engagements__path: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'engagements__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'engagements__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'engagements__engagement_type_id', mapping: 'engagement_type_id', title: 'Engagement Type', type: 'int', isFk: true, fkIdField: 'engagement_types__id', fkDisplayField: 'engagement_types__name', filterType: {"type":"EngagementTypesCombo","loadAfterRender":!1}, editorType: {"type":"EngagementTypesCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'engagements__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'engagements__path', mapping: 'path', title: 'Path', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'engagement_types__id', mapping: 'engagement_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'engagement_types__name', mapping: 'engagement_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_manager', mapping: 'user.is_manager', title: 'Is Manager?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_ftp_user', mapping: 'user.is_ftp_user', title: 'FTP User?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_id', mapping: 'user.mpp_id', title: 'MPP', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_type', mapping: 'user.mpp_type', title: 'MPP Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_terminal', mapping: 'user.mpp_terminal', title: 'MPP Terminal', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_days_since', mapping: 'user.mpp_days_since', title: 'MPP Days Since', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__hire_date', mapping: 'user.hire_date', title: 'Hire Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__date_of_birth', mapping: 'user.date_of_birth', title: 'Date Of Birth', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'EngagementTypes',
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			'engagements__datetime',
			'engagements__engagement_type_id',
			'engagements__user_id'
		],

	},

	entity: {
		methods: {

			getEngagementType: async function() {
				const EngagementTypes = this.getAssociatedRepository('EngagementTypes');
				let entity = EngagementTypes.getById(this.engagements__engagement_type_id);
				if (!entity) {
					entity = await EngagementTypes.getSingleEntityFromServer(this.engagements__engagement_type_id);
				}
				return entity;
			},

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.engagements__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.engagements__user_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Engagements;
