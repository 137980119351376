import * as yup from 'yup'; // https://github.com/jquense/yup#string

const DriverInfractions = {
	
	name: 'DriverInfractions',
	
	model: {

		idProperty: 'driver_infractions__id',
		displayProperty: 'driver_infractions__id',
		sortProperty: 'driver_infractions__id',
		
		sorters: null,

		validator: yup.object({
			driver_infractions__user_id: yup.number().integer().required(),
			driver_infractions__period: yup.number().integer().required(),
			driver_infractions__infraction_date: yup.date().required(),
			driver_infractions__category: yup.string().oneOf(['attendance','accident','par']).required()
		}),
		
		properties: [
			{ name: 'driver_infractions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'driver_infractions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__full_name', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'driver_infractions__period', mapping: 'period', title: 'Period', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'driver_infractions__infraction_date', mapping: 'infraction_date', title: 'Infraction Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: 'General', },
			{ name: 'driver_infractions__category', mapping: 'category', title: 'Category', isFilteringDisabled: true, editorType: {"type":"ArrayCombo","data":[['attendance','attendance'],['accident','accident'],['par','par']]}, fieldGroup: 'General', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_manager', mapping: 'user.is_manager', title: 'Is Manager?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__job_title', mapping: 'user.job_title', title: 'Job Title', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__is_ftp_user', mapping: 'user.is_ftp_user', title: 'FTP User?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_id', mapping: 'user.mpp_id', title: 'MPP', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_type', mapping: 'user.mpp_type', title: 'MPP Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_terminal', mapping: 'user.mpp_terminal', title: 'MPP Terminal', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__mpp_days_since', mapping: 'user.mpp_days_since', title: 'MPP Days Since', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__hire_date', mapping: 'user.hire_date', title: 'Hire Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__date_of_birth', mapping: 'user.date_of_birth', title: 'Date Of Birth', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			'driver_infractions__user_id',
			// 'driver_infractions__period',
			'driver_infractions__infraction_date'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.driver_infractions__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.driver_infractions__user_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default DriverInfractions;
