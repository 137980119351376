/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
export default {
	colors: {
		primary: {
			50: '#e09498',
			100: '#db7879',
			200: '#db5e5f',
			300: '#d14142',
			400: '#af2526', // default for buttons
			500: '#992021',
			600: '#851c1d',
			700: '#661516',
			800: '#4d1010',
			900: '#380707',
		},
		// secondary: {
		// 	500: '#1F3854', // default for buttons
		// },
		selected: '#ff0:alpha.20',
		hover: '#000:alpha.10',
		selectedHover: '#cc0',
		unselected: '#bbb',
		disabled: '#ccc',
		control: '#1B518C',
		vip: '#3a78bc',
		navBlue: '#3b82f6',
		warning: '#d92b2b',
		warningHover: '#af1313',
		orange: '#de9000',
		greens: {
			100: '#97f2d4',
			200: '#5be8bc',
			300: '#3d8C40',
			400: '#00b880',
			500: '#009568', // 
			600: '#007d57',
			700: '#006647',
			800: '#004530',
			900: '#003022',
		},
	},
	// config: {
	// 	initialColorMode: 'dark',
	// },
	Components: {
		Modal: {
			baseStyle: {
				_web: {
					zIndex: '100000',
					position: 'absolute',
				},
			},
		},
		Select: {
			defaultProps: {
				h: 50,
				flex: 1,
				bg: '#fff',
				borderWidth: 1,
				borderColor: '#000',
				isDisabled: false,
			},
		},
		Icon: {
			defaultProps: {
				size: 'md',
				color: '#fff',
			},
		},
		Input: {
			variants: {
				form: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					return {
						autoCapitalize: 'none',
						mb: 4,
						w: '100%',
						h: '50px',

						// BEGIN Taken from variant 'filled'
						bg: props.bg || (isLightMode ? 'muted.200' : 'muted.600'),
						borderWidth: '1',
						borderColor: 'transparent',
						_hover: {
							bg: isLightMode ? 'muted.200' : 'muted.700',
						},
						// END
					};
				},
			},
			defaultProps: {
				size: 'xl',
			},
		},
		Button: {
			variants: {
				footer: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					
					return {
						flex: 1,
						_text: {
							color: '#fff',
						},
						alignSelf: 'center',
						alignItems: 'center',
						justifyContent: 'center',

						// BEGIN Taken from variant 'solid'
						_web: {
							outlineWidth: '0'
						},
						bg: c + '.800',
						_hover: {
							bg: c + '.600'
						},
						_pressed: {
							bg: c + '.700'
						},
						_focus: {
							bg: c + '.600'
						},
						_loading: {
							bg: isLightMode ? 'warmGray.50' : c + '.300',
							opacity: '50'
						},
						_disabled: {
							_text: {
								color: '#000',
							},
							bg: isLightMode ? 'trueGray.400' : 'trueGray.600',
						},
						// END
					};
				},
				solid2: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					
					return {
						_text: {
							color: '#fff',
						},
						// BEGIN Taken from variant 'solid'
						_web: {
							outlineWidth: '0'
						},
						bg: 'trueGray.200',
						borderColor: c + '.800',
						borderWidth: 1,
						_hover: {
							bg: 'trueGray.400'
						},
						_pressed: {
							bg: 'trueGray.700'
						},
						_focus: {
							bg: 'trueGray.600',
						},
						_loading: {
							bg: isLightMode ? 'warmGray.50' : c + '.300',
							opacity: '50'
						},
						_disabled: {
							_text: {
								color: '#000',
							},
							bg: isLightMode ? 'trueGray.400' : 'trueGray.600',
						},
						// END
					};
				},
				money: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					
					return {
						alignSelf: 'center',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'greens.200',
						borderColor: 'greens.200',
						borderWidth: 2,
						borderRadius: 'xl',
						bg: '#fff',
					};
				},
			},
			defaultProps: {
				size: 'lg',
			},
		},
		IconButton: {
			variants: {
				footer: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					
					return {
						flex: 1,
						alignSelf: 'center',
						alignItems: 'center',
						justifyContent: 'center',

						// BEGIN Taken from variant 'solid'
						_web: {
							outlineWidth: '0'
						},
						bg: c + '.800',
						_hover: {
							bg: c + '.600'
						},
						_pressed: {
							bg: c + '.700'
						},
						_focus: {
							bg: c + '.600'
						},
						_loading: {
							bg: isLightMode ? 'warmGray.50' : c + '.300',
							opacity: '50'
						},
						_disabled: {
							bg: isLightMode ? 'trueGray.400' : 'trueGray.600',
						},
						// END
					};
				},
				solid2: (props) => {
					const c = props.colorScheme,
						m = props.colorMode,
						isLightMode = m === 'light';
					
					return {
						// BEGIN Taken from variant 'solid'
						_web: {
							outlineWidth: '0'
						},
						bg: 'trueGray.200',
						borderColor: c + '.800',
						borderWidth: 1,
						_hover: {
							bg: 'trueGray.400'
						},
						_pressed: {
							bg: 'trueGray.700'
						},
						_focus: {
							bg: 'trueGray.600',
						},
						_loading: {
							bg: isLightMode ? 'warmGray.50' : c + '.300',
							opacity: '50'
						},
						_disabled: {
							bg: isLightMode ? 'trueGray.400' : 'trueGray.600',
						},
						// END
					};
				},
			},
			defaultProps: {
				size: 'lg',
			},
		},
	},
};

