/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ParsEditor from '../Editor/ParsEditor.js';
import ParsGridColumns from './Columns/ParsGridColumns.js';

export default function ParsSideGridEditor(props) {
	return <SideGridEditor
				reference="ParsSideGridEditor"
				model="Pars"
				usePermissions={true}
				isCollapsible={false}
				Editor={ParsEditor}
				columnsConfig={ParsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}