/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EngagementTypesInlineGridEditorPanel from './EngagementTypesInlineGridEditor.js';

export default function EngagementTypesFilteredInlineGridEditorPanel(props) {
	return <EngagementTypesInlineGridEditorPanel
				reference="EngagementTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}