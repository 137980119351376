/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import EngagementsEditorWindow from '../../../Window/EngagementsEditorWindow.js';

function EngagementsComboEditor(props) {
	return <ComboEditor
				reference="EngagementsComboEditor"
				model="Engagements"
				uniqueRepository={true}
				Editor={EngagementsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default EngagementsComboEditor;