import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import DriverInfractionsFilteredGridEditor from '../components/Grid/DriverInfractionsFilteredGridEditor.js';
import DriverInfractionsFilteredSideGridEditor from '../components/Grid/DriverInfractionsFilteredSideGridEditor.js';

export default function DriverInfractionsManager(props) {
	return <ManagerScreen
				title="DriverInfractions"
				id="DriverInfractionsManager"
				fullModeComponent={<DriverInfractionsFilteredGridEditor
										reference="DriverInfractionsFilteredGridEditor"
									/>}
				sideModeComponent={<DriverInfractionsFilteredSideGridEditor
										reference="DriverInfractionsFilteredGridEditor"
									/>}
			/>;
}
