/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import DriverInfractionsEditor from '../Editor/DriverInfractionsEditor.js';
import DriverInfractionsGridColumns from './Columns/DriverInfractionsGridColumns.js';

export default function DriverInfractionsSideGridEditor(props) {
	return <SideGridEditor
				reference="DriverInfractionsSideGridEditor"
				model="DriverInfractions"
				usePermissions={true}
				isCollapsible={false}
				Editor={DriverInfractionsEditor}
				columnsConfig={DriverInfractionsGridColumns}
				
				
				{...props}
			/>;
}