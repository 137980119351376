/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverIncentivesSideGridEditor from './DriverIncentivesSideGridEditor.js';

export default function DriverIncentivesFilteredSideGridEditor(props) {
	return <DriverIncentivesSideGridEditor
				reference="DriverIncentivesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}