/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ParsInlineGridEditorPanel from './ParsInlineGridEditor.js';

export default function ParsFilteredInlineGridEditorPanel(props) {
	return <ParsInlineGridEditorPanel
				reference="ParsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}