/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EngagementTypesGridEditor from './EngagementTypesGridEditor.js';

export default function EngagementTypesFilteredGridEditor(props) {
	return <EngagementTypesGridEditor
				reference="EngagementTypesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}