/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PermissionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Overall",
		                "reference": "overall",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__user_id"
		                    },
		                    {
		                        "name": "permissions__group_id"
		                    },
		                    {
		                        "name": "permissions__view_reports"
		                    },
		                    {
		                        "name": "permissions__view_dashboard"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Attachments",
		                "reference": "attachments",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attachments"
		                    },
		                    {
		                        "name": "permissions__add_attachments"
		                    },
		                    {
		                        "name": "permissions__edit_attachments"
		                    },
		                    {
		                        "name": "permissions__delete_attachments"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Driver Incentive",
		                "reference": "driver_incentive",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_driver_incentives"
		                    },
		                    {
		                        "name": "permissions__add_driver_incentives"
		                    },
		                    {
		                        "name": "permissions__edit_driver_incentives"
		                    },
		                    {
		                        "name": "permissions__delete_driver_incentives"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Driver Infraction",
		                "reference": "driver_infraction",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_driver_infractions"
		                    },
		                    {
		                        "name": "permissions__add_driver_infractions"
		                    },
		                    {
		                        "name": "permissions__edit_driver_infractions"
		                    },
		                    {
		                        "name": "permissions__delete_driver_infractions"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Driver Insights",
		                "reference": "driver_insights",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_driver_insights"
		                    },
		                    {
		                        "name": "permissions__add_driver_insights"
		                    },
		                    {
		                        "name": "permissions__edit_driver_insights"
		                    },
		                    {
		                        "name": "permissions__delete_driver_insights"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Driver Insight Submects",
		                "reference": "driver_insight_submects",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_driver_insight_subjects"
		                    },
		                    {
		                        "name": "permissions__add_driver_insight_subjects"
		                    },
		                    {
		                        "name": "permissions__edit_driver_insight_subjects"
		                    },
		                    {
		                        "name": "permissions__delete_driver_insight_subjects"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Global Value",
		                "reference": "global_value",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_global_values"
		                    },
		                    {
		                        "name": "permissions__add_global_values"
		                    },
		                    {
		                        "name": "permissions__edit_global_values"
		                    },
		                    {
		                        "name": "permissions__delete_global_values"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups",
		                "reference": "groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups"
		                    },
		                    {
		                        "name": "permissions__add_groups"
		                    },
		                    {
		                        "name": "permissions__edit_groups"
		                    },
		                    {
		                        "name": "permissions__delete_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups Users",
		                "reference": "groups_users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups_users"
		                    },
		                    {
		                        "name": "permissions__add_groups_users"
		                    },
		                    {
		                        "name": "permissions__edit_groups_users"
		                    },
		                    {
		                        "name": "permissions__delete_groups_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Engagements",
		                "reference": "engagements",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_engagements"
		                    },
		                    {
		                        "name": "permissions__add_engagements"
		                    },
		                    {
		                        "name": "permissions__edit_engagements"
		                    },
		                    {
		                        "name": "permissions__delete_engagements"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Engagement Types",
		                "reference": "engagement_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_engagement_types"
		                    },
		                    {
		                        "name": "permissions__add_engagement_types"
		                    },
		                    {
		                        "name": "permissions__edit_engagement_types"
		                    },
		                    {
		                        "name": "permissions__delete_engagement_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Pars",
		                "reference": "pars",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pars"
		                    },
		                    {
		                        "name": "permissions__add_pars"
		                    },
		                    {
		                        "name": "permissions__edit_pars"
		                    },
		                    {
		                        "name": "permissions__delete_pars"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Permissions",
		                "reference": "permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_permissions"
		                    },
		                    {
		                        "name": "permissions__add_permissions"
		                    },
		                    {
		                        "name": "permissions__edit_permissions"
		                    },
		                    {
		                        "name": "permissions__delete_permissions"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Users",
		                "reference": "users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_users"
		                    },
		                    {
		                        "name": "permissions__add_users"
		                    },
		                    {
		                        "name": "permissions__edit_users"
		                    },
		                    {
		                        "name": "permissions__delete_users"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PermissionsEditor"
				title="Permissions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

