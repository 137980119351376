export default [
	    {
	        "id": "global_values__name",
	        "header": "Name",
	        "fieldName": "global_values__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "global_values__value",
	        "header": "Value",
	        "fieldName": "global_values__value",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];