/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverInsightsGrid from './DriverInsightsGrid.js';

export default function DriverInsightsFilteredGrid(props) {
	return <DriverInsightsGrid
				reference="DriverInsightsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}