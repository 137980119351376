/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EngagementTypesInlineGridEditor from './EngagementTypesInlineGridEditor.js';

export default function EngagementTypesFilteredInlineGridEditor(props) {
	return <EngagementTypesInlineGridEditor
				reference="EngagementTypesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}