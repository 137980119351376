/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EngagementsInlineGridEditor from './EngagementsInlineGridEditor.js';

export default function EngagementsFilteredInlineGridEditor(props) {
	return <EngagementsInlineGridEditor
				reference="EngagementsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}