/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import registerComponents from '@onehat/ui/src/Functions/registerComponents.js';

import DriverIncentivesCombo from '../components/Form/Field/Combo/DriverIncentivesCombo.js';
import DriverIncentivesComboEditor from '../components/Form/Field/Combo/DriverIncentivesComboEditor.js';
import DriverIncentivesTag from '../components/Form/Field/Tag/DriverIncentivesTag.js';
import DriverIncentivesTagEditor from '../components/Form/Field/Tag/DriverIncentivesTagEditor.js';
import DriverIncentivesGrid from '../components/Grid/DriverIncentivesGrid.js';
import DriverIncentivesGridEditor from '../components/Grid/DriverIncentivesGridEditor.js';
import DriverIncentivesSideGridEditor from '../components/Grid/DriverIncentivesSideGridEditor.js';
import DriverIncentivesInlineGridEditor from '../components/Grid/DriverIncentivesInlineGridEditor.js';
import DriverIncentivesFilteredGrid from '../components/Grid/DriverIncentivesFilteredGrid.js';
import DriverIncentivesFilteredGridEditor from '../components/Grid/DriverIncentivesFilteredGridEditor.js';
import DriverIncentivesFilteredSideGridEditor from '../components/Grid/DriverIncentivesFilteredSideGridEditor.js';
import DriverIncentivesFilteredInlineGridEditor from '../components/Grid/DriverIncentivesFilteredInlineGridEditor.js';
import DriverIncentivesGridPanel from '../components/Panel/Grid/DriverIncentivesGrid.js';
import DriverIncentivesGridEditorPanel from '../components/Panel/Grid/DriverIncentivesGridEditor.js';
import DriverIncentivesSideGridEditorPanel from '../components/Panel/Grid/DriverIncentivesSideGridEditor.js';
import DriverIncentivesInlineGridEditorPanel from '../components/Panel/Grid/DriverIncentivesInlineGridEditor.js';
import DriverIncentivesFilteredGridPanel from '../components/Panel/Grid/DriverIncentivesFilteredGrid.js';
import DriverIncentivesFilteredGridEditorPanel from '../components/Panel/Grid/DriverIncentivesFilteredGridEditor.js';
import DriverIncentivesFilteredSideGridEditorPanel from '../components/Panel/Grid/DriverIncentivesFilteredSideGridEditor.js';
import DriverIncentivesFilteredInlineGridEditorPanel from '../components/Panel/Grid/DriverIncentivesFilteredInlineGridEditor.js';
import DriverIncentivesEditor from '../components/Editor/DriverIncentivesEditor.js';
import DriverIncentivesEditorWindow from '../components/Window/DriverIncentivesEditorWindow.js';
import DriverInfractionsCombo from '../components/Form/Field/Combo/DriverInfractionsCombo.js';
import DriverInfractionsComboEditor from '../components/Form/Field/Combo/DriverInfractionsComboEditor.js';
import DriverInfractionsTag from '../components/Form/Field/Tag/DriverInfractionsTag.js';
import DriverInfractionsTagEditor from '../components/Form/Field/Tag/DriverInfractionsTagEditor.js';
import DriverInfractionsGrid from '../components/Grid/DriverInfractionsGrid.js';
import DriverInfractionsGridEditor from '../components/Grid/DriverInfractionsGridEditor.js';
import DriverInfractionsSideGridEditor from '../components/Grid/DriverInfractionsSideGridEditor.js';
import DriverInfractionsInlineGridEditor from '../components/Grid/DriverInfractionsInlineGridEditor.js';
import DriverInfractionsFilteredGrid from '../components/Grid/DriverInfractionsFilteredGrid.js';
import DriverInfractionsFilteredGridEditor from '../components/Grid/DriverInfractionsFilteredGridEditor.js';
import DriverInfractionsFilteredSideGridEditor from '../components/Grid/DriverInfractionsFilteredSideGridEditor.js';
import DriverInfractionsFilteredInlineGridEditor from '../components/Grid/DriverInfractionsFilteredInlineGridEditor.js';
import DriverInfractionsGridPanel from '../components/Panel/Grid/DriverInfractionsGrid.js';
import DriverInfractionsGridEditorPanel from '../components/Panel/Grid/DriverInfractionsGridEditor.js';
import DriverInfractionsSideGridEditorPanel from '../components/Panel/Grid/DriverInfractionsSideGridEditor.js';
import DriverInfractionsInlineGridEditorPanel from '../components/Panel/Grid/DriverInfractionsInlineGridEditor.js';
import DriverInfractionsFilteredGridPanel from '../components/Panel/Grid/DriverInfractionsFilteredGrid.js';
import DriverInfractionsFilteredGridEditorPanel from '../components/Panel/Grid/DriverInfractionsFilteredGridEditor.js';
import DriverInfractionsFilteredSideGridEditorPanel from '../components/Panel/Grid/DriverInfractionsFilteredSideGridEditor.js';
import DriverInfractionsFilteredInlineGridEditorPanel from '../components/Panel/Grid/DriverInfractionsFilteredInlineGridEditor.js';
import DriverInfractionsEditor from '../components/Editor/DriverInfractionsEditor.js';
import DriverInfractionsEditorWindow from '../components/Window/DriverInfractionsEditorWindow.js';
import DriverInsightSubjectsCombo from '../components/Form/Field/Combo/DriverInsightSubjectsCombo.js';
import DriverInsightSubjectsComboEditor from '../components/Form/Field/Combo/DriverInsightSubjectsComboEditor.js';
import DriverInsightSubjectsTag from '../components/Form/Field/Tag/DriverInsightSubjectsTag.js';
import DriverInsightSubjectsTagEditor from '../components/Form/Field/Tag/DriverInsightSubjectsTagEditor.js';
import DriverInsightSubjectsGrid from '../components/Grid/DriverInsightSubjectsGrid.js';
import DriverInsightSubjectsGridEditor from '../components/Grid/DriverInsightSubjectsGridEditor.js';
import DriverInsightSubjectsSideGridEditor from '../components/Grid/DriverInsightSubjectsSideGridEditor.js';
import DriverInsightSubjectsInlineGridEditor from '../components/Grid/DriverInsightSubjectsInlineGridEditor.js';
import DriverInsightSubjectsFilteredGrid from '../components/Grid/DriverInsightSubjectsFilteredGrid.js';
import DriverInsightSubjectsFilteredGridEditor from '../components/Grid/DriverInsightSubjectsFilteredGridEditor.js';
import DriverInsightSubjectsFilteredSideGridEditor from '../components/Grid/DriverInsightSubjectsFilteredSideGridEditor.js';
import DriverInsightSubjectsFilteredInlineGridEditor from '../components/Grid/DriverInsightSubjectsFilteredInlineGridEditor.js';
import DriverInsightSubjectsGridPanel from '../components/Panel/Grid/DriverInsightSubjectsGrid.js';
import DriverInsightSubjectsGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsGridEditor.js';
import DriverInsightSubjectsSideGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsSideGridEditor.js';
import DriverInsightSubjectsInlineGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsInlineGridEditor.js';
import DriverInsightSubjectsFilteredGridPanel from '../components/Panel/Grid/DriverInsightSubjectsFilteredGrid.js';
import DriverInsightSubjectsFilteredGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsFilteredGridEditor.js';
import DriverInsightSubjectsFilteredSideGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsFilteredSideGridEditor.js';
import DriverInsightSubjectsFilteredInlineGridEditorPanel from '../components/Panel/Grid/DriverInsightSubjectsFilteredInlineGridEditor.js';
import DriverInsightSubjectsEditor from '../components/Editor/DriverInsightSubjectsEditor.js';
import DriverInsightSubjectsEditorWindow from '../components/Window/DriverInsightSubjectsEditorWindow.js';
import DriverInsightsCombo from '../components/Form/Field/Combo/DriverInsightsCombo.js';
import DriverInsightsComboEditor from '../components/Form/Field/Combo/DriverInsightsComboEditor.js';
import DriverInsightsTag from '../components/Form/Field/Tag/DriverInsightsTag.js';
import DriverInsightsTagEditor from '../components/Form/Field/Tag/DriverInsightsTagEditor.js';
import DriverInsightsGrid from '../components/Grid/DriverInsightsGrid.js';
import DriverInsightsGridEditor from '../components/Grid/DriverInsightsGridEditor.js';
import DriverInsightsSideGridEditor from '../components/Grid/DriverInsightsSideGridEditor.js';
import DriverInsightsInlineGridEditor from '../components/Grid/DriverInsightsInlineGridEditor.js';
import DriverInsightsFilteredGrid from '../components/Grid/DriverInsightsFilteredGrid.js';
import DriverInsightsFilteredGridEditor from '../components/Grid/DriverInsightsFilteredGridEditor.js';
import DriverInsightsFilteredSideGridEditor from '../components/Grid/DriverInsightsFilteredSideGridEditor.js';
import DriverInsightsFilteredInlineGridEditor from '../components/Grid/DriverInsightsFilteredInlineGridEditor.js';
import DriverInsightsGridPanel from '../components/Panel/Grid/DriverInsightsGrid.js';
import DriverInsightsGridEditorPanel from '../components/Panel/Grid/DriverInsightsGridEditor.js';
import DriverInsightsSideGridEditorPanel from '../components/Panel/Grid/DriverInsightsSideGridEditor.js';
import DriverInsightsInlineGridEditorPanel from '../components/Panel/Grid/DriverInsightsInlineGridEditor.js';
import DriverInsightsFilteredGridPanel from '../components/Panel/Grid/DriverInsightsFilteredGrid.js';
import DriverInsightsFilteredGridEditorPanel from '../components/Panel/Grid/DriverInsightsFilteredGridEditor.js';
import DriverInsightsFilteredSideGridEditorPanel from '../components/Panel/Grid/DriverInsightsFilteredSideGridEditor.js';
import DriverInsightsFilteredInlineGridEditorPanel from '../components/Panel/Grid/DriverInsightsFilteredInlineGridEditor.js';
import DriverInsightsEditor from '../components/Editor/DriverInsightsEditor.js';
import DriverInsightsEditorWindow from '../components/Window/DriverInsightsEditorWindow.js';
import EngagementTypesCombo from '../components/Form/Field/Combo/EngagementTypesCombo.js';
import EngagementTypesComboEditor from '../components/Form/Field/Combo/EngagementTypesComboEditor.js';
import EngagementTypesTag from '../components/Form/Field/Tag/EngagementTypesTag.js';
import EngagementTypesTagEditor from '../components/Form/Field/Tag/EngagementTypesTagEditor.js';
import EngagementTypesGrid from '../components/Grid/EngagementTypesGrid.js';
import EngagementTypesGridEditor from '../components/Grid/EngagementTypesGridEditor.js';
import EngagementTypesSideGridEditor from '../components/Grid/EngagementTypesSideGridEditor.js';
import EngagementTypesInlineGridEditor from '../components/Grid/EngagementTypesInlineGridEditor.js';
import EngagementTypesFilteredGrid from '../components/Grid/EngagementTypesFilteredGrid.js';
import EngagementTypesFilteredGridEditor from '../components/Grid/EngagementTypesFilteredGridEditor.js';
import EngagementTypesFilteredSideGridEditor from '../components/Grid/EngagementTypesFilteredSideGridEditor.js';
import EngagementTypesFilteredInlineGridEditor from '../components/Grid/EngagementTypesFilteredInlineGridEditor.js';
import EngagementTypesGridPanel from '../components/Panel/Grid/EngagementTypesGrid.js';
import EngagementTypesGridEditorPanel from '../components/Panel/Grid/EngagementTypesGridEditor.js';
import EngagementTypesSideGridEditorPanel from '../components/Panel/Grid/EngagementTypesSideGridEditor.js';
import EngagementTypesInlineGridEditorPanel from '../components/Panel/Grid/EngagementTypesInlineGridEditor.js';
import EngagementTypesFilteredGridPanel from '../components/Panel/Grid/EngagementTypesFilteredGrid.js';
import EngagementTypesFilteredGridEditorPanel from '../components/Panel/Grid/EngagementTypesFilteredGridEditor.js';
import EngagementTypesFilteredSideGridEditorPanel from '../components/Panel/Grid/EngagementTypesFilteredSideGridEditor.js';
import EngagementTypesFilteredInlineGridEditorPanel from '../components/Panel/Grid/EngagementTypesFilteredInlineGridEditor.js';
import EngagementTypesEditor from '../components/Editor/EngagementTypesEditor.js';
import EngagementTypesEditorWindow from '../components/Window/EngagementTypesEditorWindow.js';
import EngagementsCombo from '../components/Form/Field/Combo/EngagementsCombo.js';
import EngagementsComboEditor from '../components/Form/Field/Combo/EngagementsComboEditor.js';
import EngagementsTag from '../components/Form/Field/Tag/EngagementsTag.js';
import EngagementsTagEditor from '../components/Form/Field/Tag/EngagementsTagEditor.js';
import EngagementsGrid from '../components/Grid/EngagementsGrid.js';
import EngagementsGridEditor from '../components/Grid/EngagementsGridEditor.js';
import EngagementsSideGridEditor from '../components/Grid/EngagementsSideGridEditor.js';
import EngagementsInlineGridEditor from '../components/Grid/EngagementsInlineGridEditor.js';
import EngagementsFilteredGrid from '../components/Grid/EngagementsFilteredGrid.js';
import EngagementsFilteredGridEditor from '../components/Grid/EngagementsFilteredGridEditor.js';
import EngagementsFilteredSideGridEditor from '../components/Grid/EngagementsFilteredSideGridEditor.js';
import EngagementsFilteredInlineGridEditor from '../components/Grid/EngagementsFilteredInlineGridEditor.js';
import EngagementsGridPanel from '../components/Panel/Grid/EngagementsGrid.js';
import EngagementsGridEditorPanel from '../components/Panel/Grid/EngagementsGridEditor.js';
import EngagementsSideGridEditorPanel from '../components/Panel/Grid/EngagementsSideGridEditor.js';
import EngagementsInlineGridEditorPanel from '../components/Panel/Grid/EngagementsInlineGridEditor.js';
import EngagementsFilteredGridPanel from '../components/Panel/Grid/EngagementsFilteredGrid.js';
import EngagementsFilteredGridEditorPanel from '../components/Panel/Grid/EngagementsFilteredGridEditor.js';
import EngagementsFilteredSideGridEditorPanel from '../components/Panel/Grid/EngagementsFilteredSideGridEditor.js';
import EngagementsFilteredInlineGridEditorPanel from '../components/Panel/Grid/EngagementsFilteredInlineGridEditor.js';
import EngagementsEditor from '../components/Editor/EngagementsEditor.js';
import EngagementsEditorWindow from '../components/Window/EngagementsEditorWindow.js';
import GlobalValuesCombo from '../components/Form/Field/Combo/GlobalValuesCombo.js';
import GlobalValuesComboEditor from '../components/Form/Field/Combo/GlobalValuesComboEditor.js';
import GlobalValuesTag from '../components/Form/Field/Tag/GlobalValuesTag.js';
import GlobalValuesTagEditor from '../components/Form/Field/Tag/GlobalValuesTagEditor.js';
import GlobalValuesGrid from '../components/Grid/GlobalValuesGrid.js';
import GlobalValuesGridEditor from '../components/Grid/GlobalValuesGridEditor.js';
import GlobalValuesSideGridEditor from '../components/Grid/GlobalValuesSideGridEditor.js';
import GlobalValuesInlineGridEditor from '../components/Grid/GlobalValuesInlineGridEditor.js';
import GlobalValuesFilteredGrid from '../components/Grid/GlobalValuesFilteredGrid.js';
import GlobalValuesFilteredGridEditor from '../components/Grid/GlobalValuesFilteredGridEditor.js';
import GlobalValuesFilteredSideGridEditor from '../components/Grid/GlobalValuesFilteredSideGridEditor.js';
import GlobalValuesFilteredInlineGridEditor from '../components/Grid/GlobalValuesFilteredInlineGridEditor.js';
import GlobalValuesGridPanel from '../components/Panel/Grid/GlobalValuesGrid.js';
import GlobalValuesGridEditorPanel from '../components/Panel/Grid/GlobalValuesGridEditor.js';
import GlobalValuesSideGridEditorPanel from '../components/Panel/Grid/GlobalValuesSideGridEditor.js';
import GlobalValuesInlineGridEditorPanel from '../components/Panel/Grid/GlobalValuesInlineGridEditor.js';
import GlobalValuesFilteredGridPanel from '../components/Panel/Grid/GlobalValuesFilteredGrid.js';
import GlobalValuesFilteredGridEditorPanel from '../components/Panel/Grid/GlobalValuesFilteredGridEditor.js';
import GlobalValuesFilteredSideGridEditorPanel from '../components/Panel/Grid/GlobalValuesFilteredSideGridEditor.js';
import GlobalValuesFilteredInlineGridEditorPanel from '../components/Panel/Grid/GlobalValuesFilteredInlineGridEditor.js';
import GlobalValuesEditor from '../components/Editor/GlobalValuesEditor.js';
import GlobalValuesEditorWindow from '../components/Window/GlobalValuesEditorWindow.js';
import GroupsCombo from '../components/Form/Field/Combo/GroupsCombo.js';
import GroupsComboEditor from '../components/Form/Field/Combo/GroupsComboEditor.js';
import GroupsTag from '../components/Form/Field/Tag/GroupsTag.js';
import GroupsTagEditor from '../components/Form/Field/Tag/GroupsTagEditor.js';
import GroupsGrid from '../components/Grid/GroupsGrid.js';
import GroupsGridEditor from '../components/Grid/GroupsGridEditor.js';
import GroupsSideGridEditor from '../components/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditor from '../components/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGrid from '../components/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditor from '../components/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditor from '../components/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditor from '../components/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsGridPanel from '../components/Panel/Grid/GroupsGrid.js';
import GroupsGridEditorPanel from '../components/Panel/Grid/GroupsGridEditor.js';
import GroupsSideGridEditorPanel from '../components/Panel/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditorPanel from '../components/Panel/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGridPanel from '../components/Panel/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditorPanel from '../components/Panel/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsEditor from '../components/Editor/GroupsEditor.js';
import GroupsEditorWindow from '../components/Window/GroupsEditorWindow.js';
import GroupsUsersCombo from '../components/Form/Field/Combo/GroupsUsersCombo.js';
import GroupsUsersComboEditor from '../components/Form/Field/Combo/GroupsUsersComboEditor.js';
import GroupsUsersTag from '../components/Form/Field/Tag/GroupsUsersTag.js';
import GroupsUsersTagEditor from '../components/Form/Field/Tag/GroupsUsersTagEditor.js';
import GroupsUsersGrid from '../components/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditor from '../components/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditor from '../components/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditor from '../components/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGrid from '../components/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditor from '../components/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditor from '../components/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditor from '../components/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersGridPanel from '../components/Panel/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditorPanel from '../components/Panel/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGridPanel from '../components/Panel/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersEditor from '../components/Editor/GroupsUsersEditor.js';
import GroupsUsersEditorWindow from '../components/Window/GroupsUsersEditorWindow.js';
import GroupsUsersGridSideA from '../components/Grid/GroupsUsersGridSideA.js';
import GroupsUsersGridSideB from '../components/Grid/GroupsUsersGridSideB.js';
import GroupsUsersGridEditorSideA from '../components/Grid/GroupsUsersGridEditorSideA.js';
import GroupsUsersGridEditorSideB from '../components/Grid/GroupsUsersGridEditorSideB.js';
import GroupsUsersFilteredGridEditorSideA from '../components/Grid/GroupsUsersFilteredGridEditorSideA.js';
import GroupsUsersFilteredGridEditorSideB from '../components/Grid/GroupsUsersFilteredGridEditorSideB.js';
import GroupsUsersEditorWindowSideA from '../components/Window/GroupsUsersEditorWindowSideA.js';
import GroupsUsersEditorWindowSideB from '../components/Window/GroupsUsersEditorWindowSideB.js';
import GroupsUsersEditorSideA from '../components/Editor/GroupsUsersEditorSideA.js';
import GroupsUsersEditorSideB from '../components/Editor/GroupsUsersEditorSideB.js';
import ParsCombo from '../components/Form/Field/Combo/ParsCombo.js';
import ParsComboEditor from '../components/Form/Field/Combo/ParsComboEditor.js';
import ParsTag from '../components/Form/Field/Tag/ParsTag.js';
import ParsTagEditor from '../components/Form/Field/Tag/ParsTagEditor.js';
import ParsGrid from '../components/Grid/ParsGrid.js';
import ParsGridEditor from '../components/Grid/ParsGridEditor.js';
import ParsSideGridEditor from '../components/Grid/ParsSideGridEditor.js';
import ParsInlineGridEditor from '../components/Grid/ParsInlineGridEditor.js';
import ParsFilteredGrid from '../components/Grid/ParsFilteredGrid.js';
import ParsFilteredGridEditor from '../components/Grid/ParsFilteredGridEditor.js';
import ParsFilteredSideGridEditor from '../components/Grid/ParsFilteredSideGridEditor.js';
import ParsFilteredInlineGridEditor from '../components/Grid/ParsFilteredInlineGridEditor.js';
import ParsGridPanel from '../components/Panel/Grid/ParsGrid.js';
import ParsGridEditorPanel from '../components/Panel/Grid/ParsGridEditor.js';
import ParsSideGridEditorPanel from '../components/Panel/Grid/ParsSideGridEditor.js';
import ParsInlineGridEditorPanel from '../components/Panel/Grid/ParsInlineGridEditor.js';
import ParsFilteredGridPanel from '../components/Panel/Grid/ParsFilteredGrid.js';
import ParsFilteredGridEditorPanel from '../components/Panel/Grid/ParsFilteredGridEditor.js';
import ParsFilteredSideGridEditorPanel from '../components/Panel/Grid/ParsFilteredSideGridEditor.js';
import ParsFilteredInlineGridEditorPanel from '../components/Panel/Grid/ParsFilteredInlineGridEditor.js';
import ParsEditor from '../components/Editor/ParsEditor.js';
import ParsEditorWindow from '../components/Window/ParsEditorWindow.js';
import PermissionsCombo from '../components/Form/Field/Combo/PermissionsCombo.js';
import PermissionsComboEditor from '../components/Form/Field/Combo/PermissionsComboEditor.js';
import PermissionsTag from '../components/Form/Field/Tag/PermissionsTag.js';
import PermissionsTagEditor from '../components/Form/Field/Tag/PermissionsTagEditor.js';
import PermissionsGrid from '../components/Grid/PermissionsGrid.js';
import PermissionsGridEditor from '../components/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditor from '../components/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditor from '../components/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGrid from '../components/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditor from '../components/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditor from '../components/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditor from '../components/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsGridPanel from '../components/Panel/Grid/PermissionsGrid.js';
import PermissionsGridEditorPanel from '../components/Panel/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditorPanel from '../components/Panel/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditorPanel from '../components/Panel/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGridPanel from '../components/Panel/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsEditor from '../components/Editor/PermissionsEditor.js';
import PermissionsEditorWindow from '../components/Window/PermissionsEditorWindow.js';
import UsersCombo from '../components/Form/Field/Combo/UsersCombo.js';
import UsersComboEditor from '../components/Form/Field/Combo/UsersComboEditor.js';
import UsersTag from '../components/Form/Field/Tag/UsersTag.js';
import UsersTagEditor from '../components/Form/Field/Tag/UsersTagEditor.js';
import UsersGrid from '../components/Grid/UsersGrid.js';
import UsersGridEditor from '../components/Grid/UsersGridEditor.js';
import UsersSideGridEditor from '../components/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditor from '../components/Grid/UsersInlineGridEditor.js';
import UsersFilteredGrid from '../components/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditor from '../components/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditor from '../components/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditor from '../components/Grid/UsersFilteredInlineGridEditor.js';
import UsersGridPanel from '../components/Panel/Grid/UsersGrid.js';
import UsersGridEditorPanel from '../components/Panel/Grid/UsersGridEditor.js';
import UsersSideGridEditorPanel from '../components/Panel/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditorPanel from '../components/Panel/Grid/UsersInlineGridEditor.js';
import UsersFilteredGridPanel from '../components/Panel/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditorPanel from '../components/Panel/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditorPanel from '../components/Panel/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/UsersFilteredInlineGridEditor.js';
import UsersEditor from '../components/Editor/UsersEditor.js';
import UsersEditorWindow from '../components/Window/UsersEditorWindow.js';

export default function registerComponentsFn() {

	registerComponents({
		DriverIncentivesCombo,
		DriverIncentivesComboEditor,
		DriverIncentivesTag,
		DriverIncentivesTagEditor,
		DriverIncentivesGrid,
		DriverIncentivesGridEditor,
		DriverIncentivesSideGridEditor,
		DriverIncentivesInlineGridEditor,
		DriverIncentivesFilteredGrid,
		DriverIncentivesFilteredGridEditor,
		DriverIncentivesFilteredSideGridEditor,
		DriverIncentivesFilteredInlineGridEditor,
		DriverIncentivesGridPanel,
		DriverIncentivesGridEditorPanel,
		DriverIncentivesSideGridEditorPanel,
		DriverIncentivesInlineGridEditorPanel,
		DriverIncentivesFilteredGridPanel,
		DriverIncentivesFilteredGridEditorPanel,
		DriverIncentivesFilteredSideGridEditorPanel,
		DriverIncentivesFilteredInlineGridEditorPanel,
		DriverIncentivesEditor,
		DriverIncentivesEditorWindow,
		DriverInfractionsCombo,
		DriverInfractionsComboEditor,
		DriverInfractionsTag,
		DriverInfractionsTagEditor,
		DriverInfractionsGrid,
		DriverInfractionsGridEditor,
		DriverInfractionsSideGridEditor,
		DriverInfractionsInlineGridEditor,
		DriverInfractionsFilteredGrid,
		DriverInfractionsFilteredGridEditor,
		DriverInfractionsFilteredSideGridEditor,
		DriverInfractionsFilteredInlineGridEditor,
		DriverInfractionsGridPanel,
		DriverInfractionsGridEditorPanel,
		DriverInfractionsSideGridEditorPanel,
		DriverInfractionsInlineGridEditorPanel,
		DriverInfractionsFilteredGridPanel,
		DriverInfractionsFilteredGridEditorPanel,
		DriverInfractionsFilteredSideGridEditorPanel,
		DriverInfractionsFilteredInlineGridEditorPanel,
		DriverInfractionsEditor,
		DriverInfractionsEditorWindow,
		DriverInsightSubjectsCombo,
		DriverInsightSubjectsComboEditor,
		DriverInsightSubjectsTag,
		DriverInsightSubjectsTagEditor,
		DriverInsightSubjectsGrid,
		DriverInsightSubjectsGridEditor,
		DriverInsightSubjectsSideGridEditor,
		DriverInsightSubjectsInlineGridEditor,
		DriverInsightSubjectsFilteredGrid,
		DriverInsightSubjectsFilteredGridEditor,
		DriverInsightSubjectsFilteredSideGridEditor,
		DriverInsightSubjectsFilteredInlineGridEditor,
		DriverInsightSubjectsGridPanel,
		DriverInsightSubjectsGridEditorPanel,
		DriverInsightSubjectsSideGridEditorPanel,
		DriverInsightSubjectsInlineGridEditorPanel,
		DriverInsightSubjectsFilteredGridPanel,
		DriverInsightSubjectsFilteredGridEditorPanel,
		DriverInsightSubjectsFilteredSideGridEditorPanel,
		DriverInsightSubjectsFilteredInlineGridEditorPanel,
		DriverInsightSubjectsEditor,
		DriverInsightSubjectsEditorWindow,
		DriverInsightsCombo,
		DriverInsightsComboEditor,
		DriverInsightsTag,
		DriverInsightsTagEditor,
		DriverInsightsGrid,
		DriverInsightsGridEditor,
		DriverInsightsSideGridEditor,
		DriverInsightsInlineGridEditor,
		DriverInsightsFilteredGrid,
		DriverInsightsFilteredGridEditor,
		DriverInsightsFilteredSideGridEditor,
		DriverInsightsFilteredInlineGridEditor,
		DriverInsightsGridPanel,
		DriverInsightsGridEditorPanel,
		DriverInsightsSideGridEditorPanel,
		DriverInsightsInlineGridEditorPanel,
		DriverInsightsFilteredGridPanel,
		DriverInsightsFilteredGridEditorPanel,
		DriverInsightsFilteredSideGridEditorPanel,
		DriverInsightsFilteredInlineGridEditorPanel,
		DriverInsightsEditor,
		DriverInsightsEditorWindow,
		EngagementTypesCombo,
		EngagementTypesComboEditor,
		EngagementTypesTag,
		EngagementTypesTagEditor,
		EngagementTypesGrid,
		EngagementTypesGridEditor,
		EngagementTypesSideGridEditor,
		EngagementTypesInlineGridEditor,
		EngagementTypesFilteredGrid,
		EngagementTypesFilteredGridEditor,
		EngagementTypesFilteredSideGridEditor,
		EngagementTypesFilteredInlineGridEditor,
		EngagementTypesGridPanel,
		EngagementTypesGridEditorPanel,
		EngagementTypesSideGridEditorPanel,
		EngagementTypesInlineGridEditorPanel,
		EngagementTypesFilteredGridPanel,
		EngagementTypesFilteredGridEditorPanel,
		EngagementTypesFilteredSideGridEditorPanel,
		EngagementTypesFilteredInlineGridEditorPanel,
		EngagementTypesEditor,
		EngagementTypesEditorWindow,
		EngagementsCombo,
		EngagementsComboEditor,
		EngagementsTag,
		EngagementsTagEditor,
		EngagementsGrid,
		EngagementsGridEditor,
		EngagementsSideGridEditor,
		EngagementsInlineGridEditor,
		EngagementsFilteredGrid,
		EngagementsFilteredGridEditor,
		EngagementsFilteredSideGridEditor,
		EngagementsFilteredInlineGridEditor,
		EngagementsGridPanel,
		EngagementsGridEditorPanel,
		EngagementsSideGridEditorPanel,
		EngagementsInlineGridEditorPanel,
		EngagementsFilteredGridPanel,
		EngagementsFilteredGridEditorPanel,
		EngagementsFilteredSideGridEditorPanel,
		EngagementsFilteredInlineGridEditorPanel,
		EngagementsEditor,
		EngagementsEditorWindow,
		GlobalValuesCombo,
		GlobalValuesComboEditor,
		GlobalValuesTag,
		GlobalValuesTagEditor,
		GlobalValuesGrid,
		GlobalValuesGridEditor,
		GlobalValuesSideGridEditor,
		GlobalValuesInlineGridEditor,
		GlobalValuesFilteredGrid,
		GlobalValuesFilteredGridEditor,
		GlobalValuesFilteredSideGridEditor,
		GlobalValuesFilteredInlineGridEditor,
		GlobalValuesGridPanel,
		GlobalValuesGridEditorPanel,
		GlobalValuesSideGridEditorPanel,
		GlobalValuesInlineGridEditorPanel,
		GlobalValuesFilteredGridPanel,
		GlobalValuesFilteredGridEditorPanel,
		GlobalValuesFilteredSideGridEditorPanel,
		GlobalValuesFilteredInlineGridEditorPanel,
		GlobalValuesEditor,
		GlobalValuesEditorWindow,
		GroupsCombo,
		GroupsComboEditor,
		GroupsTag,
		GroupsTagEditor,
		GroupsGrid,
		GroupsGridEditor,
		GroupsSideGridEditor,
		GroupsInlineGridEditor,
		GroupsFilteredGrid,
		GroupsFilteredGridEditor,
		GroupsFilteredSideGridEditor,
		GroupsFilteredInlineGridEditor,
		GroupsGridPanel,
		GroupsGridEditorPanel,
		GroupsSideGridEditorPanel,
		GroupsInlineGridEditorPanel,
		GroupsFilteredGridPanel,
		GroupsFilteredGridEditorPanel,
		GroupsFilteredSideGridEditorPanel,
		GroupsFilteredInlineGridEditorPanel,
		GroupsEditor,
		GroupsEditorWindow,
		GroupsUsersCombo,
		GroupsUsersComboEditor,
		GroupsUsersTag,
		GroupsUsersTagEditor,
		GroupsUsersGrid,
		GroupsUsersGridEditor,
		GroupsUsersSideGridEditor,
		GroupsUsersInlineGridEditor,
		GroupsUsersFilteredGrid,
		GroupsUsersFilteredGridEditor,
		GroupsUsersFilteredSideGridEditor,
		GroupsUsersFilteredInlineGridEditor,
		GroupsUsersGridPanel,
		GroupsUsersGridEditorPanel,
		GroupsUsersSideGridEditorPanel,
		GroupsUsersInlineGridEditorPanel,
		GroupsUsersFilteredGridPanel,
		GroupsUsersFilteredGridEditorPanel,
		GroupsUsersFilteredSideGridEditorPanel,
		GroupsUsersFilteredInlineGridEditorPanel,
		GroupsUsersEditor,
		GroupsUsersEditorWindow,
		GroupsUsersGridSideA,
		GroupsUsersGridSideB,
		GroupsUsersGridEditorSideA,
		GroupsUsersGridEditorSideB,
		GroupsUsersFilteredGridEditorSideA,
		GroupsUsersFilteredGridEditorSideB,
		GroupsUsersEditorWindowSideA,
		GroupsUsersEditorWindowSideB,
		GroupsUsersEditorSideA,
		GroupsUsersEditorSideB,
		ParsCombo,
		ParsComboEditor,
		ParsTag,
		ParsTagEditor,
		ParsGrid,
		ParsGridEditor,
		ParsSideGridEditor,
		ParsInlineGridEditor,
		ParsFilteredGrid,
		ParsFilteredGridEditor,
		ParsFilteredSideGridEditor,
		ParsFilteredInlineGridEditor,
		ParsGridPanel,
		ParsGridEditorPanel,
		ParsSideGridEditorPanel,
		ParsInlineGridEditorPanel,
		ParsFilteredGridPanel,
		ParsFilteredGridEditorPanel,
		ParsFilteredSideGridEditorPanel,
		ParsFilteredInlineGridEditorPanel,
		ParsEditor,
		ParsEditorWindow,
		PermissionsCombo,
		PermissionsComboEditor,
		PermissionsTag,
		PermissionsTagEditor,
		PermissionsGrid,
		PermissionsGridEditor,
		PermissionsSideGridEditor,
		PermissionsInlineGridEditor,
		PermissionsFilteredGrid,
		PermissionsFilteredGridEditor,
		PermissionsFilteredSideGridEditor,
		PermissionsFilteredInlineGridEditor,
		PermissionsGridPanel,
		PermissionsGridEditorPanel,
		PermissionsSideGridEditorPanel,
		PermissionsInlineGridEditorPanel,
		PermissionsFilteredGridPanel,
		PermissionsFilteredGridEditorPanel,
		PermissionsFilteredSideGridEditorPanel,
		PermissionsFilteredInlineGridEditorPanel,
		PermissionsEditor,
		PermissionsEditorWindow,
		UsersCombo,
		UsersComboEditor,
		UsersTag,
		UsersTagEditor,
		UsersGrid,
		UsersGridEditor,
		UsersSideGridEditor,
		UsersInlineGridEditor,
		UsersFilteredGrid,
		UsersFilteredGridEditor,
		UsersFilteredSideGridEditor,
		UsersFilteredInlineGridEditor,
		UsersGridPanel,
		UsersGridEditorPanel,
		UsersSideGridEditorPanel,
		UsersInlineGridEditorPanel,
		UsersFilteredGridPanel,
		UsersFilteredGridEditorPanel,
		UsersFilteredSideGridEditorPanel,
		UsersFilteredInlineGridEditorPanel,
		UsersEditor,
		UsersEditorWindow
	});
	
}
