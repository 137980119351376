import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import ParsFilteredGridEditor from '../components/Grid/ParsFilteredGridEditor.js';
import ParsFilteredSideGridEditor from '../components/Grid/ParsFilteredSideGridEditor.js';

export default function ParsManager(props) {	
	return <ManagerScreen
				title="Pars"
				id="ParsManager"
				fullModeComponent={<ParsFilteredGridEditor
										reference="ParsFilteredGridEditor"
										ParemoteDuplicate={true}
									/>}
				sideModeComponent={<ParsFilteredSideGridEditor
										reference="ParsFilteredGridEditor"
										ParemoteDuplicate={true}
									/>}
			/>;
}
