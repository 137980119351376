export default [
	    {
	        "id": "engagements__datetime",
	        "header": "Datetime",
	        "fieldName": "engagements__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "engagements__engagement_type_id",
	        "header": "Engagement Type",
	        "fieldName": "engagement_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "engagements__engagement_type_id"
	    },
	    {
	        "id": "engagements__user_id",
	        "header": "User",
	        "fieldName": "users__full_name",
	        "isSortable": false,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "engagements__user_id"
	    },
	    {
	        "id": "engagements__path",
	        "header": "Path",
	        "fieldName": "engagements__path",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250, // MOD
	    }
	];