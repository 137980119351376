/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ParsGrid from './ParsGrid.js';

export default function ParsFilteredGrid(props) {
	return <ParsGrid
				reference="ParsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}