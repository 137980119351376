import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import GroupsFilteredGridEditor from '../components/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditor from '../components/Grid/GroupsFilteredSideGridEditor.js';

export default function GroupsManager(props) {	
	return <ManagerScreen
				title="Groups"
				id="GroupsManager"
				fullModeComponent={<GroupsFilteredGridEditor
										reference="groupsFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
				sideModeComponent={<GroupsFilteredSideGridEditor
										reference="groupsFilteredGridEditor"
										useRemoteDuplicate={true}
									/>}
			/>;
}
