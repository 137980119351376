/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import EngagementTypesSideGridEditorPanel from './EngagementTypesSideGridEditor.js';


export default function EngagementTypesFilteredSideGridEditorPanel(props) {
	return <EngagementTypesSideGridEditorPanel
				reference="EngagementTypesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}