/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverInsightSubjectsInlineGridEditorPanel from './DriverInsightSubjectsInlineGridEditor.js';

export default function DriverInsightSubjectsFilteredInlineGridEditorPanel(props) {
	return <DriverInsightSubjectsInlineGridEditorPanel
				reference="DriverInsightSubjectsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}