import { useState, useEffect, } from 'react';
import {
	Text,
} from 'native-base';
import { Linking } from 'react-native';
import Url from 'url-parse';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox'
import _ from 'lodash';
	
export default function NotFound() {
	const
		[path, setPath] = useState(0),
		[isReady, setIsReady] = useState(false);

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			setPath(path);
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	return <CenterBox>
				<Text fontSize={20}>Page not found.</Text>
				<Text>{path}</Text>
			</CenterBox>;

}
