/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DriverInsightSubjectsSideGridEditor from './DriverInsightSubjectsSideGridEditor.js';

export default function DriverInsightSubjectsFilteredSideGridEditor(props) {
	return <DriverInsightSubjectsSideGridEditor
				reference="DriverInsightSubjectsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}